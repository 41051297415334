import { useEffect, useState } from "react";
import axios from "axios";

const localCache = {};

const UseCatChook = (cat) => {
  const [catC, setCatC] = useState([]);

  useEffect(() => {
    if (!cat) {
      setCatC([]);
    } else if (localCache[cat]) {
      setCatC(localCache[cat]);
    } else {
      requestCatC();
    }

    async function requestCatC() {
      setCatC([]);
      const res = await axios.get(
        `https://classibazaar.com.au/cbazaar_api/api/v3/post_categories?parent_id=${cat}`
      );
      localCache[cat] = res?.data || [];
      setCatC(localCache[cat]);
    }
  }, [cat]);

  return catC;
};

export default UseCatChook;
