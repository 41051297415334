import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Button, Empty } from "antd";
import AddSupportTicket from "./AddSupportTicket";
import SupportTicketDetail from "./SupportTicketDetail";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";
import moment from "moment";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Support",
    link: "/support",
  },
];

export default function Support() {
  const { supportList } = useSelector((store) => store?.AgentAgencyReducer);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [support, setSupport] = useState(null);

  useEffect(() => {
    fetchSupportList();
    fetchSupportTypeCategoryList();
  }, []);

  const fetchSupportList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "support_list",
          attempt: "FETCH_SUPPORT_LIST_REQUEST_ATTEMPT",
          success: "FETCH_SUPPORT_LIST_REQUEST_SUCCESS",
          failure: "FETCH_SUPPORT_LIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const fetchSupportTypeCategoryList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "support",
          attempt: "FETCH_SUPPORT_TYPE_REQUEST_ATTEMPT",
          success: "FETCH_SUPPORT_TYPE_REQUEST_SUCCESS",
          failure: "FETCH_SUPPORT_TYPE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <DashboardLayout>
      <Breadcrumb title="Support" crumbList={crumbList} />

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <Button
          onClick={() => setOpen(true)}
          style={{ margin: "0.5rem 0" }}
          type="primary"
        >
          Add Ticket
        </Button>
        {/* <Button.Group style={{ display: "flex", gap: "0.5rem" }}>
          <Button type="primary">Pending</Button>
          <Button type="primary" danger>
            Solved
          </Button>
        </Button.Group> */}
      </div>
      <div
        style={{
          margin: "0.5rem 0",
          padding: "1rem",
          background: "white",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "0.5rem",
        }}
      >
        {supportList ? (
          supportList?.results?.map((supportData) => (
            <TicketCard
              supportData={supportData}
              setIsDrawerOpen={() => setIsDrawerOpen(true)}
              setSupport={setSupport}
            />
          ))
        ) : (
          <Empty style={{ gridColumn: "span 2" }} />
        )}
      </div>

      {open && (
        <AddSupportTicket
          fetchSupportList={fetchSupportList}
          open={open}
          setOpen={setOpen}
        />
      )}
      {isDrawerOpen && (
        <SupportTicketDetail
          support={support}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}
    </DashboardLayout>
  );
}

export const TicketCard = ({ setIsDrawerOpen, supportData, setSupport }) => {
  return (
    <div
      onClick={() => {
        setSupport(supportData);
        setIsDrawerOpen();
      }}
      className="support--card"
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "0.5rem 1rem",
        borderRadius: "0.5rem",
        border: "1px solid #cfcfcf60",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
        <span
          style={{
            border: "1px solid #cfcfcf",
            padding: "0.25rem 0.8rem",
            borderRadius: "1rem",
          }}
        >
          #{supportData?.id}
        </span>
        <span
          style={{
            border: "1px solid #cfcfcf",
            padding: "0.25rem 0.8rem",
            borderRadius: "1rem",
          }}
        >
          {supportData?.type}
        </span>
        <div
          style={{
            flex: "1",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {supportData?.status === "Pending" ? (
            <span
              style={{
                border: "1px solid orange",
                padding: "0.25rem 0.8rem",
                borderRadius: "1rem",
                background: "orange",
                color: "white",
              }}
            >
              {supportData?.status}
            </span>
          ) : (
            <span
              style={{
                border: "1px solid #cfcfcf",
                padding: "0.25rem 0.8rem",
                borderRadius: "1rem",
                background: "#3fb865",
                color: "white",
              }}
            >
              {supportData?.status}
            </span>
          )}
        </div>
      </div>
      <h3 style={{ marginTop: "0.5rem" }}>{supportData?.message}</h3>
      <span>
        {moment(supportData?.gmt).local().format("DD MMMM YYYY hh:mm a")}
      </span>
    </div>
  );
};
