import React, { useState } from "react";
import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Tabs } from "antd";
import InquiryListComponent from "./InquiryListComponent";
import SubscriptionListComponent from "./SubscriptionListComponent";
// import ReviewComponent from "./ReviewComponent";
// import ReviewedComponent from "./ReviewedComponent";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Payment History",
    link: "/payment-history",
  },
];

const items = [
  {
    key: "0",
    label: "Credit List",
  },
  {
    key: "1",
    label: "Subscription List",
  },
];

const mapStateToProps = (store) => {
  const { PropertyReducer } = store;
  return { PropertyReducer };
};

export default function PaymentHistroy() {
  const [current, setCurrent] = useState(0);

  const activeComponent = [
    <InquiryListComponent />,
    <SubscriptionListComponent />,
  ];
  return (
    <DashboardLayout>
      <Breadcrumb title="Payment History" crumbList={crumbList} />
      <Tabs
        size="large"
        defaultActiveKey={current}
        items={items}
        onChange={(value) => {
          setCurrent(value);
        }}
      />
      {activeComponent[current]}
    </DashboardLayout>
  );
}
