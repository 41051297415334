import { Button, Form, Input, Modal, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";

export default function AddSupportTicket({ open, setOpen, fetchSupportList }) {
  const { supportType } = useSelector((store) => store?.AgentAgencyReducer);
  const dispatch = useDispatch();
  const { Option } = Select;

  const handleSubmitTicket = (values) => {
    dispatch(
      allActions(values, {
        method: "post",
        endPoint: "support",
        attempt: "POST_SUPPORT_TICKET_REQUEST_ATTEMPT",
        success: "POST_SUPPORT_TICKET_REQUEST_SUCCESS",
        failure: "POST_SUPPORT_TICKET_REQUEST_FAILURE",

        saveBearerToken: false,
        successInternalState: (res) => {
          setOpen(false);
          fetchSupportList();
        },
        failureInternalState: () => {},
      })
    );
  };

  return (
    <Modal
      onCancel={() => {
        setOpen(false);
      }}
      open={open}
      title="Add Support Ticket"
      footer={false}
    >
      <Form layout="vertical" onFinish={handleSubmitTicket}>
        <Form.Item
          label="Problem Type"
          name="problem_type"
          rules={[
            {
              required: true,
              message: "Problem type is required",
            },
          ]}
        >
          <Select placeholder="Select problem type">
            {supportType?.types?.map((type) => (
              <Option value={type}>{type}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Problem Category"
          name="problem_category"
          rules={[
            {
              required: true,
              message: "Problem category is required",
            },
          ]}
        >
          <Select placeholder="Select problem category">
            {supportType?.categories?.map((cat) => (
              <Option value={cat}>{cat}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: "Message is required",
            },
          ]}
        >
          <Input.TextArea rows={6} placeholder="Your message" />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Add Ticket
        </Button>
      </Form>
    </Modal>
  );
}
