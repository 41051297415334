import React, { useState, useEffect } from "react";
import Card from "antd/es/card/Card";
import { Button, Divider, Empty, Select, Skeleton, Timeline } from "antd";
import { Line } from "@ant-design/plots";
import axios from "axios";

import { connect, useDispatch } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { getCookie, timeValueFormatter } from "../../Helpers/FrontendHelper";
import { timeDifferenceCalculation } from "../../Helpers/FrontendHelper";
import { useNavigate, NavLink } from "react-router-dom";
import moment from "moment";

const mapStateToProps = (store) => {
  const { PropertyReducer, ProfileReducer } = store;
  return { PropertyReducer, ProfileReducer };
};

const ActivityAdView = ({ PropertyReducer, ProfileReducer }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { loading, myActivity, dashboardData } = ProfileReducer;
  const { realEstateData, allPropertyData } = PropertyReducer;

  const weekdaysOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  useEffect(() => {
    asyncFetch();
    myActivityLogs();
  }, []);

  const asyncFetch = () => {
    const token = getCookie("accessToken");
    axios
      .get(`https://classibazaar.com.au/cbazaar_api/api/v3/chart`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const data = response?.data;
        const dataEntries = Object.entries(data);
        const sortedDataEntries = dataEntries.sort(
          ([weekdayA], [weekdayB]) =>
            weekdaysOrder.indexOf(weekdayA) - weekdaysOrder.indexOf(weekdayB)
        );
        const sortedDataObject = Object.fromEntries(sortedDataEntries);

        return sortedDataObject;
      })
      .then((data) => {
        const dataArray = Object.keys(data).map((key) => ({
          weekday: key,
          value: parseInt(data[key]),
        }));
        return setData(dataArray);
      })

      .catch((error) => {});
  };

  const handleSelectProperty = (value) => {
    if (value !== "all") {
      const token = getCookie("accessToken");
      axios
        .get(
          `https://classibazaar.com.au/cbazaar_api/api/v3/ads_views?post_id=${value}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          const data = response?.data;
          const dataEntries = Object.entries(data);
          const sortedDataEntries = dataEntries.sort(
            ([weekdayA], [weekdayB]) =>
              weekdaysOrder.indexOf(weekdayA) - weekdaysOrder.indexOf(weekdayB)
          );
          const sortedDataObject = Object.fromEntries(sortedDataEntries);

          return sortedDataObject;
        })
        .then((data) => {
          const dataArray = Object?.keys(data)?.map((key) => ({
            weekday: key,
            value: parseInt(data[key]),
          }));
          return setData(dataArray);
        })

        .catch((error) => {});
    } else {
      asyncFetch();
    }
  };

  const myActivityLogs = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "myactivity",
          attempt: "FETCH_MYACTIVITY_REQUEST_ATTEMPT",
          success: "FETCH_MYACTIVITY_REQUEST_SUCCESS",
          failure: "FETCH_MYACTIVITY_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const config = {
    data,
    xField: "weekday",
    yField: "value",
    point: {
      size: 5,
      shape: "diamond",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
    xAxis: {
      title: {
        text: "Weekday",
      },
    },
    yAxis: {
      title: {
        text: "Views",
      },
    },
  };

  return (
    <div className="activiyadview--container">
      <div className="activityadview--card">
        <h3 style={{ padding: "1rem" }}>News</h3>
        <Divider style={{ margin: "0" }} />
        <Timeline
          className="overflow--scroll"
          style={{
            maxHeight: "19rem",
            overflowY: "scroll",
            padding: "1rem",
          }}
          items={dashboardData?.notices?.map((item) => {
            return {
              children: (
                <div
                  style={{
                    color: "slategrey",
                    fontWeight: 600,
                  }}
                >
                  <span>{item?.name}</span>
                  <p style={{ fontWeight: 400, fontSize: "12px" }}>
                    {item?.date}
                  </p>
                  <p>{item?.content}</p>
                </div>
              ),
            };
          })}
        />{" "}
      </div>

      <RecentApprovedAdComponent
        PropertyReducer={PropertyReducer}
        dashboardData={dashboardData}
      />
    </div>
  );
};

export default connect(mapStateToProps)(ActivityAdView);

export const RecentApprovedAdComponent = ({
  PropertyReducer,
  dashboardData,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { approvedadloading, recentApprovedAd } = PropertyReducer;

  return (
    <div className="activityadview--card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem",
        }}
      >
        <h3>Recent Approved Leads</h3>
      </div>
      <Divider style={{ margin: "0" }} />
      {approvedadloading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            maxHeight: "18rem",
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
          }}
        >
          <ActiveAdSkeleton />
          <ActiveAdSkeleton />
          <ActiveAdSkeleton />
          <ActiveAdSkeleton />
        </div>
      ) : (
        <div
          className="overflow--scroll"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            maxHeight: "18rem",
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
            padding: "1rem",
            height: "100%",
          }}
        >
          {dashboardData?.recent_approved?.length > 0 ? (
            dashboardData?.recent_approved?.map((item) => {
              return (
                <NavLink>
                  <div className="approvedad--element--container">
                    <img
                      style={{
                        width: "3rem",
                        height: "3rem",
                        objectFit: "cover",
                        borderRadius: "3px",
                        border: "1px solid #80808080",
                        background: "white",
                      }}
                      src={item?.cat_image}
                      alt={item?.cat_name}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        color: "slategrey",
                        fontWeight: 600,
                      }}
                    >
                      <span>{item?.cat_name}</span>
                      <span style={{ fontWeight: 400, fontSize: "12px" }}>
                        {" "}
                        {item?.approve_at}
                      </span>
                    </div>
                  </div>
                </NavLink>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </div>
      )}
    </div>
  );
};

export const ActiveAdSkeleton = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        background: "#f8f8f8",
        padding: "0.5rem",
        borderRadius: "0.5rem",
        alignItems: "center",
      }}
    >
      <Skeleton.Image style={{ width: "3rem", height: "3rem" }} active={true} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.3rem",
        }}
      >
        <Skeleton.Button
          active={true}
          block={true}
          style={{ width: "8rem", height: "1rem", borderRadius: "3px" }}
        />
        <Skeleton.Button
          active={true}
          block={true}
          style={{ width: "4rem", height: "1rem", borderRadius: "1rem" }}
        />
      </div>
    </div>
  );
};
