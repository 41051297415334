import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Input, message } from "antd";
import { useDispatch, connect } from "react-redux";
import Autocomplete from "react-google-autocomplete";

import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";

import logo3 from "../../assets/classileads.png";
import AddServiceModal from "./AddServiceModal";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const AgentRequest = (AuthReducer) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [locationDetail, setLocationDetail] = useState({
    suburb: null,
    state: null,
    postcode: null,
    address: null,
  });
  const [form] = Form.useForm();

  const { loading } = AuthReducer;

  useEffect(() => {
    fetchLeadCategory();
  }, []);

  useEffect(() => {
    const isGmail = localStorage.getItem("isGmail");
    if (isGmail === "true") {
      const tempData = JSON.parse(localStorage.getItem("glogin"));
      dispatch(
        allActions(tempData, {
          method: "post",
          endPoint: "social_authenticate",
          attempt: "GOOGLE_LOGIN_REQUEST_ATTEMPT",
          success: "GOOGLE_LOGIN_REQUEST_SUCCESS",
          failure: "GOOGLE_LOGIN_REQUEST_FAILURE",

          saveBearerToken: true,
          successInternalState: (res) => {
            localStorage.setItem("userId", res?.profile?.userid);
          },
          failureInternalState: (err) => {},
        })
      );
    } else {
      dispatch(
        allActions(
          {
            email: localStorage.getItem("userEmail"),
            password: localStorage.getItem("userCode"),
          },
          {
            method: "post",
            endPoint: "authenticate",
            attempt: "LOGIN_REQUEST_ATTEMPT",
            success: "LOGIN_REQUEST_SUCCESS",
            failure: "LOGIN_REQUEST_FAILURE",

            saveBearerToken: true,
            successInternalState: (res) => {},
            failureInternalState: () => {},
          }
        )
      );
    }
  }, []);

  const fetchLeadCategory = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "lead_leave_category",
          attempt: "FETCH_LEAD_CAT_REQUEST_ATTEMPT",
          success: "FETCH_LEAD_CAT_REQUEST_SUCCESS",
          failure: "FETCH_LEAD_CAT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: (res) => {
            message.warning(res?.message);
          },
        }
      )
    );
  };

  const handleSubmitAgentRequest = (values) => {
    dispatch(
      allActions(
        {
          company_abn: values?.abnNumber,
          company_name: values?.companyName,
          module_id: 6,
          app_name: "CLASSILEADS",
          address: values?.address,
          license_number: values?.license_number,
          address: locationDetail?.address,
          suburb: locationDetail?.suburb,
          state: locationDetail?.state,
          postcode: locationDetail?.postcode,
        },
        {
          method: "post",
          endPoint: "agency_request",
          attempt: "AGENCY_REQUEST_ATTEMPT",
          success: "AGENCY_REQUEST_SUCCESS",
          failure: "AGENCY_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            message.success(res?.message);
            // navigate("/");
            // localStorage.clear();
            setInterval(() => {
              setOpenModal(true);
            }, 1500);
          },
          failureInternalState: (res) => {
            message.warning(res?.message);
          },
        }
      )
    );
  };

  // const handleSubmitAgentRequest = (values) => {
  //   dispatch(
  //     allActions(
  //       {
  //         email: localStorage.getItem("userEmail"),
  //         password: localStorage.getItem("userCode"),
  //       },
  //       {
  //         method: "post",
  //         endPoint: "authenticate",
  //         attempt: "LOGIN_REQUEST_ATTEMPT",
  //         success: "LOGIN_REQUEST_SUCCESS",
  //         failure: "LOGIN_REQUEST_FAILURE",

  //         saveBearerToken: true,
  //         successInternalState: (res) => {
  //           dispatch(
  //             allActions(
  //               {
  //                 company_abn: values?.abnNumber,
  //                 company_name: values?.companyName,
  //                 module_id: 6,
  //                 app_name: "CLASSILEADS",
  //               },
  //               {
  //                 method: "post",
  //                 endPoint: "agency_request",
  //                 attempt: "AGENCY_REQUEST_ATTEMPT",
  //                 success: "AGENCY_REQUEST_SUCCESS",
  //                 failure: "AGENCY_REQUEST_FAILURE",

  //                 saveBearerToken: false,
  //                 successInternalState: (res) => {
  //                   message.success(res?.message);
  //                   // navigate("/");
  //                   // localStorage.clear();
  //                   setInterval(() => {
  //                     setOpenModal(true);
  //                   }, 1500);
  //                 },
  //                 failureInternalState: (res) => {
  //                   message.warning(res?.message);
  //                 },
  //               }
  //             )
  //           );
  //         },
  //         failureInternalState: () => {},
  //       }
  //     )
  //   );
  // };

  return (
    <div className="verifyotp--container">
      <div className="verifyotp--container--body">
        {loading && <LoaderPrimary />}

        <Form
          layout="vertical"
          className="form--container"
          onFinish={handleSubmitAgentRequest}
          form={form}
        >
          <img
            src={logo3}
            style={{
              width: "17rem",
              height: "auto",
              cursor: "pointer",
            }}
            alt="classiEstate Logo"
            onClick={() => {
              navigate("/");
            }}
          />
          <p
            className="login--header"
            style={{ fontWeight: "semibold", marginBottom: "1rem" }}
          >
            Agent Request
          </p>

          <Form.Item
            className="login--input"
            name="companyName"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>Company is required</span>
                ),
              },
            ]}
          >
            <Input
              className="login--input login--email"
              size="large"
              placeholder="Company Name"
            />
          </Form.Item>

          <Form.Item
            className="login--input"
            name="abnNumber"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    ABN number is required
                  </span>
                ),
              },
            ]}
          >
            <Input
              className="login--input login--email"
              size="large"
              type="number"
              placeholder="ABN Number"
            />
          </Form.Item>
          <Form.Item
            className="login--input"
            name="license_number"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    License number is required
                  </span>
                ),
              },
            ]}
          >
            <Input
              className="login--input login--email"
              size="large"
              type="number"
              placeholder="License Number"
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Company address is required",
              },
            ]}
            name="location"
            className="login--input"
          >
            <Autocomplete
              placeholder="Company Address"
              apiKey="AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU"
              style={{
                width: "100%",
                outline: "none",
                height: "2rem",
                border: "1px solid #D8D9DA",
                borderRadius: "1.5rem !important",
                padding: "0.5rem 0.8rem",
              }}
              onPlaceSelected={(place) => {
                form.setFieldsValue({ location: place?.formatted_address });

                setLocationDetail((prev) => ({
                  address: place?.formatted_address,
                  state: place?.address_components?.find((item) =>
                    item.types.includes("administrative_area_level_1")
                  )?.long_name,
                  suburb: place?.address_components?.find((item) =>
                    item.types.includes("locality")
                  )?.long_name,
                  postcode: place?.address_components?.find((item) =>
                    item.types.includes("postal_code")
                  )?.long_name,
                }));
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "au" },
              }}
            />
          </Form.Item>

          <button
            disabled={loading}
            type="submit"
            style={{
              outline: "0",
              border: "0",
              margin: 0,
              borderRadius: "1rem !important",
              margin: "0",
              width: "100%",
              fontSize: "1rem",
            }}
            className="login--button"
          >
            Submit{" "}
          </button>
        </Form>
      </div>

      {openModal && (
        <AddServiceModal openModal={openModal} setOpenModal={setOpenModal} />
      )}
    </div>
  );
};

export default connect(mapStateToProps)(AgentRequest);
