import React, { useEffect, useState, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, connect } from "react-redux";
import { Card, Divider, Modal, Timeline } from "antd";
import { SettingOutlined, UserSwitchOutlined } from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import {
  FeatureProperty,
  ActivityAdView,
  TodoList,
  QuickInquiry,
} from "../../component";
import { allActions } from "../../Redux/myActions";
import { PieChart } from "../../component/charts/PieChart";
import OffersMessage from "../../component/offerMessage/OffersMessage";
import LatestProperty from "../../component/insight/LatestProperty";
import PieChartSkeleton from "../../skeleton/insight/PieChartSkeleton";
import { GiTwoCoins } from "react-icons/gi";
import { timeValueFormatter } from "../../Helpers/FrontendHelper";
import moment from "moment";

const mapStateToProps = (store) => {
  const { PropertyReducer, ProfileReducer } = store;
  return { PropertyReducer, ProfileReducer };
};

const Insight = ({ PropertyReducer, ProfileReducer }) => {
  const dispatch = useDispatch();
  const { changetodoloading, myActivity, dashboardData } = ProfileReducer;
  const { loading, realEstateData } = PropertyReducer;
  useEffect(() => {
    window.scrollTo(0, { behavior: "smooth" });
  }, []);

  useLayoutEffect(() => {
    // dispatch(
    //   allActions(
    //     {},
    //     {
    //       method: "get",
    //       endPoint: "realstate_profile",
    //       attempt: "REALESTATE_PROFILE_REQUEST_ATTEMPT",
    //       success: "REALESTATE_PROFILE_REQUEST_SUCCESS",
    //       failure: "REALESTATE_PROFILE_REQUEST_FAILURE",

    //       saveBearerToken: false,
    //       successInternalState: () => {},
    //       failureInternalState: () => {},
    //     }
    //   )
    // );
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile_new",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            // localStorage.setItem("is_agent", res?.is_agent);
          },
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const [soldRented, setSoldRented] = useState([]);
  const [offerInquiry, setOfferInquiry] = useState([]);
  const [sellRent, setSellRent] = useState([]);
  const [AdStatus, setAdStatus] = useState([]);

  useEffect(() => {
    // if (PropertyReducer?.realEstateData) {
    //   const product_details =
    //     PropertyReducer?.realEstateData?.realestate_detail;

    setOfferInquiry([
      {
        type: "Inquiry",
        value:
          dashboardData?.pending_declined_accepted?.length +
          dashboardData?.closed?.length,
      },
    ]);

    setAdStatus([
      {
        type: "Pending",
        value: dashboardData?.pending_declined_accepted?.filter(
          (data) => data?.status === "Pending"
        )?.length,
      },
      {
        type: "Decline",
        value: dashboardData?.pending_declined_accepted?.filter(
          (data) => data?.status === "Declined"
        )?.length,
      },
      {
        type: "Accepted",
        value: dashboardData?.pending_declined_accepted?.filter(
          (data) => data?.status === "Approved"
        )?.length,
      },
      {
        type: "Closed",
        value: dashboardData?.closed?.length,
      },
    ]);

    setSellRent([
      {
        type: "Hired",
        value: dashboardData?.hired_accepted?.length,
      },
      // {
      //   type: "Accepted",
      //   value: 12,
      // },
    ]);

    setSoldRented([
      {
        type: "Sold Out",
        value: 1,
      },
      {
        type: "Rented",
        value: 1,
      },
      {
        type: "Remain",
        value: 1,
      },
    ]);
    // }
  }, [dashboardData]);

  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
  ];

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "lead_dashboard",
          attempt: "FETCH_DASHBOARD_DATA_REQUEST_ATTEMPT",
          success: "FETCH_DASHBOARD_DATA_REQUEST_SUCCESS",
          failure: "FETCH_DASHBOARD_DATA_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <DashboardLayout>
      <div className="dashboard--container">
        <Breadcrumb title="DashBoard" crumbList={crumbList} />
        <div className="notification--container" style={{ width: "100%" }}>
          <div style={{ flex: "1" }}>
            <div
              style={{
                padding: "1rem",
                borderRadius: "1rem",
                background: "#e6f4ff",
                width: "fit-content",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.5rem 1.5rem",
              }}
            >
              <span> Subscription Type: </span>
              <span style={{ textAlign: "end" }}>
                {dashboardData?.subscription_type === "monthly_enquiry_credit"
                  ? "Monthly"
                  : dashboardData?.subscription_type === "yearly_enquiry_credit"
                  ? "Yearly"
                  : dashboardData?.subscription_type === "weekly_enquiry_credit"
                  ? "Weekly"
                  : "NaN"}
              </span>
              <span> Subsription Remaining: </span>
              <span style={{ textAlign: "end" }}>
                {" "}
                {moment
                  .utc(dashboardData?.subscription_expire_at, "DD-MM-YYYY")
                  .local()
                  .startOf("seconds")
                  .fromNow() ?? "N/A"}
              </span>
              <span> Classi-coins:</span>
              <span
                style={{
                  color: "orange",
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.2rem",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <GiTwoCoins style={{ fontSize: "2rem" }} />{" "}
                <span style={{ color: "#0b3e75ce", fontWeight: 600 }}>
                  {dashboardData?.classi_coin}
                </span>
              </span>{" "}
            </div>
          </div>
          <div className="notification--container--body">
            <NavLink
              to="https://classibazaar.com.au/notification_setting_list"
              className="button--modal--primary"
              style={{ gap: "0.5rem" }}
            >
              <SettingOutlined /> {`Notification Settings`}
            </NavLink>
            <NavLink
              to="/referral/invite-friend"
              className="button--modal--primary"
              style={{ gap: "0.5rem" }}
            >
              <UserSwitchOutlined /> {`  Invite Friends`}
            </NavLink>
          </div>
        </div>
        {/* {loading ? (
          <PieChartSkeleton />
        ) : ( */}
        <div className="piechart--property--container">
          {AdStatus && <PieChart data={AdStatus} />}
          {/* {soldRented && <PieChart data={soldRented} />} */}
          <PieChart data={sellRent} />
          {offerInquiry && <PieChart data={offerInquiry} />}
        </div>
        {/* )} */}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <ActivityAdView />
        </div>
        {/* <div className="todo--offermessage--container"> */}
        {/* <OffersMessage /> */}
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
          }}
        >
          <TodoList />

          <div className="activityadview--card">
            <h3 style={{ padding: "1rem" }}>My Activity Log</h3>
            <Divider style={{ margin: "0" }} />

            <Timeline
              className="overflow--scroll"
              style={{
                maxHeight: "19rem",
                overflowY: "scroll",
                padding: "1rem",
              }}
              items={[
                ...myActivity?.map((item) => {
                  return {
                    children: (
                      <div
                        style={{
                          color: "slategrey",
                          fontWeight: 600,
                        }}
                      >
                        <span>{item?.title}</span>
                        <p style={{ fontWeight: 400, fontSize: "12px" }}>
                          {timeValueFormatter(item?.created_date)}
                        </p>
                      </div>
                    ),
                  };
                }),
              ]}
            />
          </div>
        </div>
        {/* </div> */}
        {/* <LatestProperty /> */}
        <QuickInquiry />
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(Insight);
