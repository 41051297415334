import React, { useEffect } from "react";

import { Button, DatePicker, Divider, Select, Table } from "antd";
import { useDispatch, connect } from "react-redux";
import { allActions } from "../../Redux/myActions";
import MainLoader from "../../component/loader/MainLoader";
import { timeValueFormatter } from "../../Helpers/FrontendHelper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";

const mapStateToProps = (store) => {
  const { PropertyReducer } = store;
  return { PropertyReducer };
};

function InquiryListComponent({ PropertyReducer }) {
  const dispatch = useDispatch();
  const { loading, inquiryCreditList } = PropertyReducer;

  useEffect(() => {
    fetchInquiryCreditList(1);
  }, []);

  const fetchInquiryCreditList = (pageNo) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `inquiry_credit_list?page=${pageNo}`,
          attempt: "FETCH_INQUIRY_CREDITLIST_REQUEST_ATTEMPT",
          success: "FETCH_INQUIRY_CREDITLIST_REQUEST_SUCCESS",
          failure: "FETCH_INQUIRY_CREDITLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };
  return (
    <div>
      {" "}
      <MyAdPaymentComponent
        fetchInquiryCreditList={fetchInquiryCreditList}
        inquiryCreditList={inquiryCreditList}
      />
    </div>
  );
}

export default connect(mapStateToProps)(InquiryListComponent);

export const MyAdPaymentComponent = ({
  inquiryCreditList,
  fetchInquiryCreditList,
}) => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "Id",
      align: "center",
      titleStyle: {
        backgroundColor: "#0b3f77ce !important",
        color: "white !important",
      },
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "Credits",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "Price",
      align: "center",
    },
    {
      title: "Invoice Status",
      dataIndex: "payment_status",
      key: "Invoice Status",
      align: "center",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "Payment Method",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "added_at",
      key: "Date",
      align: "center",
      // render: (date) => timeValueFormatter(date),
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
  ];
  return (
    <div className="inquiry--creditlist--container--body">
      <Table
        scroll={{ y: 400, x: 800 }}
        pagination={false}
        bordered
        dataSource={inquiryCreditList?.results}
        columns={columns}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.5rem",
          alignItems: "center",
          padding: "1rem 1rem 1rem 0",
        }}
      >
        <Button
          disabled={Number(inquiryCreditList?.current_page) === 1}
          onClick={() =>
            fetchInquiryCreditList(Number(inquiryCreditList?.current_page) - 1)
          }
        >
          <LeftOutlined />
        </Button>
        <Button className="button--modal--primary" type="primary">
          {inquiryCreditList?.current_page}
        </Button>
        <Button
          onClick={() =>
            fetchInquiryCreditList(Number(inquiryCreditList?.current_page) + 1)
          }
          disabled={
            !inquiryCreditList?.has_next_page ||
            inquiryCreditList?.results?.length === 0
          }
        >
          <RightOutlined />
        </Button>
      </div>
    </div>
  );
};
