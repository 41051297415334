import React, { useState } from "react";
import { Button, Checkbox, Divider, Form, Input, Radio } from "antd";
import { useEffect } from "react";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import MainLoader from "../../component/loader/MainLoader";
import { CloseCircleOutlined } from "@ant-design/icons";
import Autocomplete from "react-google-autocomplete";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Inquiry Setting",
    link: "/find-agent-inquiry/setting",
  },
];

const mapStateToProps = (store) => {
  const { AgentAgencyReducer } = store;
  return { AgentAgencyReducer };
};

const InquirySetting = ({ AgentAgencyReducer }) => {
  const dispatch = useDispatch();
  const { agentInquirySetting, loading } = AgentAgencyReducer;
  const [value, setValue] = useState(false);
  const [form] = Form.useForm();
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    fetchInquirySetting();
  }, []);

  const fetchInquirySetting = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "lead_enquiry_settings",
          attempt: "FETCH_INQUIRY_SETTING_REQUEST_ATTEMPT",
          success: "FETCH_INQUIRY_SETTING_REQUEST_SUCCESS",
          failure: "FETCH_INQUIRY_SETTING_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setLocationList(
              res?.setting?.all_aus === "1"
                ? []
                : res?.setting_locations?.map((item) => ({
                    ...item,
                    address: item?.location,
                  }))
            );

            setValue(res?.setting?.all_aus === "1" ? true : false);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleUpdateInquirySetting = (values) => {
    dispatch(
      allActions(
        {
          all_aus: value ? "1" : "0",
          inquiry_for: values?.inquiry_for,
          locations: locationList,
        },
        {
          method: "post",
          endPoint: "lead_enquiry_settings",
          attempt: "POST_INQUIRY_SETTING_REQUEST_ATTEMPT",
          success: "POST_INQUIRY_SETTING_REQUEST_SUCCESS",
          failure: "POST_INQUIRY_SETTING_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            fetchInquirySetting();
            setValue(false);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <DashboardLayout>
      {loading && <MainLoader />}
      <Breadcrumb title="Inquiry Setting" crumbList={crumbList} />
      <div className="inquiry--setting--container">
        {agentInquirySetting && (
          <Form
            initialValues={{
              inquiry_for: agentInquirySetting?.setting_categories?.map(
                (data) => data?.category_id
              ),
              locations: "",
            }}
            form={form}
            layout="vertical"
            onFinish={handleUpdateInquirySetting}
          >
            <Form.Item
              rules={[
                {
                  required: value || locationList?.length === 0 ? true : false,
                  message: "Property address is required",
                },
              ]}
              name="locations"
              label={
                <span style={{ fontSize: "15px" }}>
                  Location of the property?
                </span>
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                {!value && (
                  <Autocomplete
                    apiKey="AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU"
                    style={{
                      width: "90%",
                      outline: "none",
                      height: "2rem",
                      border: "1px solid #D8D9DA",
                      padding: "0 0.5rem",
                    }}
                    onPlaceSelected={(place) => {
                      setLocationList((prev) => [
                        ...prev,
                        {
                          address: place?.formatted_address,
                          state: place?.address_components?.find((item) =>
                            item.types.includes("administrative_area_level_1")
                          )?.long_name,
                          suburb: place?.address_components?.find((item) =>
                            item.types.includes("locality")
                          )?.long_name,
                          postcode: place?.address_components?.find((item) =>
                            item.types.includes("postal_code")
                          )?.long_name,
                        },
                      ]);

                      // setLocationList((prev) => [
                      //   ...prev,
                      //   place?.formatted_address,
                      // ]);
                    }}
                    options={{
                      // types: ["(regions)"],
                      types: ["address"],
                      componentRestrictions: { country: "au" },
                    }}
                  />
                )}

                <Checkbox
                  checked={value}
                  onChange={() => setValue((prev) => !prev)}
                  style={{ flexBasis: "40%" }}
                >
                  All over Australia
                </Checkbox>
              </div>
            </Form.Item>
            {locationList?.length > 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.5rem",
                  maxHeight: "6rem",
                  overflowY: "scroll",
                  padding: "1rem",
                  backgroundColor: "#f5f5f5",
                  marginBottom: "1rem",
                }}
              >
                {locationList?.map((loc, index) => (
                  <div
                    key={`${loc?.address} ${index}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      gap: "0.5rem",
                      padding: "0.25rem",
                      border: "1px solid #cfcfcf",
                      borderRadius: "0.5rem",
                      background: "white",
                    }}
                  >
                    {loc?.address}{" "}
                    <CloseCircleOutlined
                      onClick={() =>
                        setLocationList(
                          locationList?.filter(
                            (item) => item?.address !== loc?.address
                          )
                        )
                      }
                      style={{
                        cursor: "pointer",
                        color: "red",
                        fontSize: "1.2rem",
                      }}
                    />{" "}
                  </div>
                ))}
              </div>
            )}

            <Form.Item label="Category" name={"inquiry_for"}>
              <Checkbox.Group
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "0.5rem",
                  maxHeight: "20rem",
                  overflowY: "scroll",
                }}
              >
                {/* {agentInquirySetting?.lead_categories?.map((item) => (
                  <Checkbox value={item?.cat_id}>{item?.cat_name}</Checkbox>
                ))} */}
                {agentInquirySetting?.lead_categories
                  ?.filter((item) =>
                    agentInquirySetting?.selected_categories_on_signup?.includes(
                      item?.cat_id
                    )
                  )
                  ?.map((itm) => (
                    <Checkbox value={itm?.cat_id}>{itm?.cat_name}</Checkbox>
                  ))}
              </Checkbox.Group>
            </Form.Item>

            <Button
              type="primary"
              style={{
                width: "100%",
                margin: "2rem 0 0",
                borderRadius: "2rem",
              }}
              htmlType="submit"
              className="button--modal inquiry--setting--button"
            >
              Update
            </Button>
          </Form>
        )}
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(InquirySetting);
