import { BrowserRouter, Routes, Route } from "react-router-dom";

import PrivateRoute from "./Helpers/PrivateRoute";
import {
  Signup,
  Login,
  Verifyotp,
  Forgetpassword,
  SetNewPassword,
  Insight,
  Profile,
  AdProperty,
  InviteFriend,
  Agent,
  Coupon,
  Property,
  PrivacySetting,
  Payment,
  ReferralDashboard,
  InquiryCreditList,
  InquryList,
  InquiryCredit,
  InquirySetting,
  AgentInquiry,
  InquiryDetail,
  OfferMessagePage,
  AgencyInquiryPage,
  IntegrationPage,
  MyReviews,
  Packages,
  Support,
} from "./Pages";

import "./App.css";
import AgentRequest from "./Pages/auth/AgentRequest";
import PaymentHistroy from "./Pages/paymentHistory/PaymentHistroy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/signup" element={<Signup />} />
        <Route path="/" element={<Login />} />
        <Route path="/auth/verify-otp" element={<Verifyotp />} />
        <Route path="/auth/agent-request" element={<AgentRequest />} />
        <Route path="/auth/forget-password" element={<Forgetpassword />} />
        <Route path="/auth/change-password" element={<SetNewPassword />} />

        {/* dashboard */}
        <Route
          path="/dashboard"
          element={
            // <PrivateRoute>
            <Insight />
            // </PrivateRoute>
          }
        />

        {/* profile */}
        <Route
          path="/profile-setting"
          element={
            // <PrivateRoute>
            <Profile />
            // </PrivateRoute>
          }
        />

        {/* property */}
        <Route
          path="/ad-property"
          element={
            // <PrivateRout
            <AdProperty />
            // </PrivateRoute>
          }
        />

        <Route
          path="/my-property/:propertyStatus"
          element={
            // <PrivateRoute>
            <Property />
            // </PrivateRoute>
          }
        />

        {/* referral */}
        <Route path="/referral/invite-friend" element={<InviteFriend />} />
        <Route path="/referral/ref-dashboard" element={<ReferralDashboard />} />

        {/* agent */}
        <Route path="/agents" element={<Agent />} />

        {/* coupon */}
        <Route
          path="/coupon"
          element={
            <PrivateRoute>
              <Coupon />
            </PrivateRoute>
          }
        />

        {/* privacy setting */}
        <Route path="/privacy-setting" element={<PrivacySetting />} />

        {/* payment */}
        <Route path="/payment" element={<Payment />} />

        {/* agent inqury list */}
        <Route
          path="/find-agent-inquiry/inquiry-credit-list"
          element={<InquiryCreditList />}
        />
        <Route
          path="/find-agent-inquiry/inquiry-list"
          element={<InquryList />}
        />
        <Route
          path="/find-agent-inquiry/inquiry-credits"
          element={<InquiryCredit />}
        />

        <Route
          path="/find-agent-inquiry/setting"
          element={<InquirySetting />}
        />

        <Route path="/enquiry-detail/:inquiryId" element={<InquiryDetail />} />

        {/* offermessage */}
        <Route path="/offer-message/agent-inquiry" element={<AgentInquiry />} />
        <Route
          path="/offer-message/offer-received"
          element={<OfferMessagePage />}
        />
        <Route
          path="/offer-message/agency-inquiry"
          element={<AgencyInquiryPage />}
        />

        <Route path="/integration" element={<IntegrationPage />} />
        <Route path="/my-reviews" element={<MyReviews />} />
        <Route path="/packages" element={<Packages />} />
        <Route path="/payment-history" element={<PaymentHistroy />} />
        <Route path="/support" element={<Support />} />
      </Routes>{" "}
    </BrowserRouter>
  );
}

export default App;
