import { Button, Form, Input, Modal, Select, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";

export default function AddSupportReply({
  openReply,
  setOpenReply,
  supportId,
  fetchSupportTypeCategoryList,
}) {
  const dispatch = useDispatch();

  const handleSubmitTicketReply = (values) => {
    dispatch(
      allActions(
        { message: values.message, support_id: supportId },
        {
          method: "post",
          endPoint: "support_reply",
          attempt: "POST_SUPPORT_REPLY_REQUEST_ATTEMPT",
          success: "POST_SUPPORT_REPLY_REQUEST_SUCCESS",
          failure: "POST_SUPPORT_REPLY_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            message.success("Your reply is sent");
            setOpenReply(false);
            fetchSupportTypeCategoryList();
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <Modal
      onCancel={() => {
        setOpenReply(false);
      }}
      open={openReply}
      title="Your Reply"
      footer={false}
    >
      <Form layout="vertical" onFinish={handleSubmitTicketReply}>
        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: "Message is required",
            },
          ]}
        >
          <Input.TextArea rows={8} placeholder="Your message" />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Reply{" "}
        </Button>
      </Form>
    </Modal>
  );
}
