import React from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
const SuccessElement = ({ setCurrent }) => {
  const naviagate = useNavigate();
  return (
    <div>
      <Result
        status="success"
        title="Your subscription is successful!"
        subTitle="Thank you for taking subscription of classileads."
        extra={[
          <Button type="primary" onClick={() => naviagate("/dashboard")}>
            Go to Dashboard
          </Button>,
          <Button onClick={() => setCurrent(0)}>
            Make Subscription Again !
          </Button>,
        ]}
      />
    </div>
  );
};
export default SuccessElement;
