import { Button, DatePicker, Form, Input, Radio, message } from "antd";
import React, { useState } from "react";
import stripe from "../../assets/stripe.png";
import wallet from "../../assets/classi-WALLET.png";
import StripeApi from "../payment/StripeApi";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";
import MainLoader from "../loader/MainLoader";

export default function PaymentElement({
  setCurrent,
  subscriptionForm,
  setSubscriptionForm,
}) {
  const dispatch = useDispatch();
  const [finalLoading, setFinalLoading] = useState(false);

  const handleSubmitPayment = async (values) => {
    setFinalLoading(true);
    const newDate = new Date(values?.expDate);
    const year = newDate?.getFullYear();
    const month = newDate?.getMonth();
    const creditPrice = subscriptionForm?.packagePrice;

    if (values?.paymentMethod === "stripe") {
      // setPaymentLoading(true);
      try {
        const stripeResponse = await StripeApi?.tokens({
          card: {
            number: values?.cardNumber,
            year: year,
            month: Number(month) < 10 ? `0${month}` : month,
            cvc: values?.cvcNumber,
          },
        });

        if (!stripeResponse?.id) {
          throw new Error(stripeResponse);
        }

        const customerResposne = await StripeApi?.customers({
          id: stripeResponse?.id,
          email: values?.email,
        });
        if (!customerResposne?.default_source) {
          throw new Error(customerResposne);
        }

        const chargeResponse = await StripeApi?.charges({
          postData: {
            amount: Number(subscriptionForm?.packagePrice),
          },
          orderId: {
            // first_name: "test",
            // last_name: "case",
            email: values?.email,
          },
          customers: customerResposne?.id,
        });
        if (!chargeResponse?.data?.id) {
          throw new Error(chargeResponse);
        }
        // setPaymentLoading(false);
        chargeResponse.status === 200 &&
          dispatch(
            allActions(
              {
                price: subscriptionForm?.packagePrice,
                subscription_type: subscriptionForm?.subscription_type,
                payment_method: "Stripe",
                txn_number: chargeResponse?.data?.id,
              },
              {
                method: "post",
                endPoint: "enquiry_credit_subscription",
                attempt: "POST_CREDIT_SUBSCRIPTION_DETAILS_ATTEMPT",
                success: "POST_CREDIT_SUBSCRIPTION_DETAILS_SUCCESS",
                failure: "POST_CREDIT_SUBSCRIPTION_DETAILS_FAILURE",

                saveBearerToken: false,
                successInternalState: () => {
                  setFinalLoading(false);

                  setCurrent((prev) => prev + 1);
                },
                failureInternalState: () => {},
              }
            )
          );
      } catch (error) {
        // setPaymentLoading(false);
        message.error(error?.response?.data?.error?.code);
      }
    } else {
      dispatch(
        allActions(
          {
            // credits: creditPrice[0],
            // price: creditPrice[1],
            payment_method: "Classi Credit",
            txn_number: "",
          },
          {
            method: "post",
            endPoint: "inquiry_credits",
            attempt: "POST_INQUIRY_CREDIT_DETAILS_ATTEMPT",
            success: "POST_INQUIRY_CREDIT_DETAILS_SUCCESS",
            failure: "POST_INQUIRY_CREDIT_DETAILS_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {},
            failureInternalState: () => {},
          }
        )
      );
    }
  };
  return (
    <>
      {finalLoading && <MainLoader />}
      <Form
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          width: "100%",
        }}
        layout="vertical"
        initialValues={{
          paymentMethod: subscriptionForm?.paymentMethod,
          email: subscriptionForm?.email,
          cardName: subscriptionForm?.cardName,
          cardNumber: subscriptionForm?.cardNumber,
          expDate: subscriptionForm?.expDate,
          cvcNumber: setSubscriptionForm?.cvcNumber,
        }}
        onFinish={handleSubmitPayment}
      >
        <div
          style={{
            padding: "0 2rem",
            margin: "0",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <h3>PAYMENT METHOD</h3>
          <Form.Item name="paymentMethod">
            <Radio.Group
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Radio.Button
                value="stripe"
                style={{
                  height: "4rem",
                  borderRadius: "0",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={stripe}
                    alt=""
                    style={{
                      width: "5rem",
                      height: "auto",
                      objectFit: "content",
                    }}
                  />
                  <h3>Stripe</h3>
                </div>
              </Radio.Button>
              {/* <Radio.Button
                value="classi-credit"
                style={{
                  height: "4rem",
                  borderRadius: "0",
                  padding: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={wallet}
                    alt=""
                    style={{
                      width: "5rem",
                      height: "auto",
                      objectFit: "content",
                    }}
                  />
                  <h3>Classi-Wallet</h3>
                </div>
              </Radio.Button> */}
            </Radio.Group>
          </Form.Item>
        </div>

        <div
          style={{ display: "flex", flexDirection: "column", gpa: "0.5rem" }}
        >
          <h3 style={{ marginBottom: "0.5rem" }}>PAYMENT DETAILS</h3>

          <Form.Item label="Email Address" name="email">
            <Input size="large" placeholder="Enter your email address" />
          </Form.Item>
          <Form.Item label="Card Name" name="cardName">
            <Input size="large" placeholder="Enter the name on the card" />
          </Form.Item>
          <Form.Item label="Card Number" name="cardNumber">
            <Input
              type="number"
              size="large"
              placeholder="Enter your card number"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              style={{ width: "50%" }}
              label="Exp. Date"
              name="expDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                size="large"
                picker="month"
              />
            </Form.Item>
            <Form.Item label="CVC" name="cvcNumber">
              <Input type="number" size="large" placeholder="CVC" />
            </Form.Item>
          </div>
        </div>
        <div
          style={{
            gridColumn: "span 2",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            // onClick={() => setCurrent((prev) => prev + 1)}
          >
            Checkout Payment
          </Button>
        </div>
      </Form>
    </>
  );
}
