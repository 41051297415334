import { useDispatch, useSelector } from "react-redux";
import ScreenSize from "../../component/screenSizeReader/ScreenSize";
import {
  Button,
  Card,
  Col,
  ColorPicker,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Popover,
  Space,
  Steps,
  Tag,
} from "antd";
import { useEffect, useState } from "react";
import MainLoader from "../../component/loader/MainLoader";
import PersonalInformation from "./profileEditComponent/PersonalInformation";
import ImageGallary from "./profileEditComponent/ImageGallary";
import CompanyDocument from "./profileEditComponent/CompanyDocument";
import CompanyReferences from "./profileEditComponent/CompanyReferences";

export const CompanyDetail = ({
  profileData,
  open,
  setOpen,
  fetchProfileUpdate,
  profileRecord,
}) => {
  const drawerWidth = ScreenSize({ width: "75%" });
  const { profileloading } = useSelector((store) => store?.ProfileReducer);

  const [current, setCurrent] = useState(0);
  const [formData, setFormData] = useState({
    email: profileData?.user_email,
    first_name: profileData?.first_name,
    last_name: profileData?.last_name,
    phone: profileData?.contact_number,
    mobile: profileData?.user_mobile,
    company_name: profileData?.company_name,
    company_color: profileData?.company_color ? profileData?.company_color : "",
    agent_color: profileData?.agent_color ? profileData?.agent_color : "",
    address: profileData?.street_address,
    font_color: profileData?.font_color,
    abn: profileData?.abn ? parseInt(profileData?.abn) : null,
    license_number: profileData?.license_number,
    bio: profileData?.bio,

    images: profileRecord?.lead_profile?.images?.map((data) => ({
      image_name: data?.image,
      image_thumbnail_name: data?.thumbnail,
    })),

    documents: profileRecord?.lead_profile?.documents?.map((data) => ({
      ...data,
      url: data?.doccument,
      type: data?.doc_type_id,
    })),

    references: profileRecord?.lead_profile?.references,
  });

  const mutlStepForm = [
    <PersonalInformation
      formData={formData}
      setFormData={setFormData}
      setCurrent={setCurrent}
    />,
    <ImageGallary
      formData={formData}
      setFormData={setFormData}
      setCurrent={setCurrent}
    />,
    <CompanyDocument
      formData={formData}
      setFormData={setFormData}
      setCurrent={setCurrent}
    />,
    <CompanyReferences
      formData={formData}
      setFormData={setFormData}
      setCurrent={setCurrent}
      setOpen={setOpen}
      fetchProfileUpdate={fetchProfileUpdate}
    />,
  ];

  return (
    <Drawer
      title="Edit Profile Details"
      placement="right"
      onClose={() => {
        setOpen(false);
        setCurrent(0);
      }}
      open={open}
      width={drawerWidth}
    >
      {profileloading && <MainLoader />}

      <Steps
        size="large"
        style={{ cursor: "pointer" }}
        current={current}
        items={[
          {
            title: "Personal Information",
          },
          {
            title: "Image Gallery",
          },
          {
            title: "Document",
          },
          {
            title: "Reference",
          },
        ]}
      />
      <div style={{ margin: "2rem" }}>{mutlStepForm[current]}</div>
    </Drawer>
  );
};
