import React, { useState, useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";
import { FileImageOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Divider,
  Empty,
  Form,
  Input,
  Modal,
  Table,
  Tag,
} from "antd";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import ProfileSkeleton from "../../skeleton/profile/ProfileSkeleton";
import { CompanyDetail } from "./EditProfileDetail";
import MainLoader from "../../component/loader/MainLoader";
import { VscVerified } from "react-icons/vsc";
import { GoUnverified } from "react-icons/go";
import { IoDocument } from "react-icons/io5";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Profile",
    link: "/profile-setting",
  },
];

const mapStateToProps = (store) => {
  const { ProfileReducer } = store;
  return { ProfileReducer };
};

const Profile = ({ ProfileReducer }) => {
  const dispatch = useDispatch();
  const {
    loading,
    profileData,
    logoLoading,
    profileLoading,
    profileImage,
    companyImage,
    docTypeList,
  } = ProfileReducer;
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchProfileUpdate();
    fetchDocumentType();
  }, []);

  const fetchProfileUpdate = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile_new",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const fetchDocumentType = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "doc_types",
          attempt: "FETCH_DOCUMENT_TYPE_REQUEST_ATTEMPT",
          success: "FETCH_DOCUMENT_TYPE_REQUEST_SUCCESS",
          failure: "FETCH_DOCUMENT_TYPE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  if (loading) {
    return (
      <DashboardLayout>
        <Breadcrumb title="Profile" crumbList={crumbList} />
        <div className="profile--component--loader">
          <ProfileSkeleton />
          <ProfileSkeleton />
        </div>
      </DashboardLayout>
    );
  }

  const columns = [
    {
      title: "S.N",
      dataIndex: "id",
    },
    {
      title: "Document Type",
      dataIndex: "name",
      //   render: (docId) =>
      //     docTypeList?.types?.find((data) => data?.id === docId)?.name,
      // },
    },
    {
      title: "Document",
      dataIndex: "doccument",
      render: (data) => (
        <>
          <IoDocument style={{ color: "blue" }} />
          {data}
        </>
        // <img
        //   src={data}
        //   alt="Document_file"
        //   style={{ width: "4rem", height: "auto" }}
        // />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data) =>
        data === "Unverified" ? (
          <Tag color="red">{data}</Tag>
        ) : (
          <Tag color="green">{data}</Tag>
        ),
    },
  ];

  const referenceColumn = [
    {
      title: "Contact Name",
      dataIndex: "name",
    },
    {
      title: "Company Name",
      dataIndex: "company",
    },
    {
      title: "Designation",
      dataIndex: "designation",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
  ];

  return (
    <DashboardLayout>
      <Breadcrumb title="Profile" crumbList={crumbList} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "0.5rem",
          marginTop: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Button style={{ borderRadius: "0" }} onClick={() => setOpen(true)}>
            Edit Profile
          </Button>
          {open && (
            <CompanyDetail
              fetchProfileUpdate={fetchProfileUpdate}
              profileData={profileData?.users}
              open={open}
              setOpen={setOpen}
              profileRecord={profileData}
            />
          )}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Button style={{ borderRadius: "0" }} onClick={showModal}>
            Change Password
          </Button>
          <PasswordComponent
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
          />
        </div>
      </div>
      <div className="profile--setting--container">
        <div className="profile--setting--inner--container">
          <div className="profile--setting--inner--leftright--container">
            {" "}
            <ProfilePicture
              profile={profileData?.users?.image}
              loading={profileLoading}
              profileImage={profileImage}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                justifyContent: "flex-start",
                width: "fit-content",
              }}
            >
              <div className="two--row--container-grid">
                <strong>Name:</strong>
                <span>
                  {profileData?.users?.first_name}{" "}
                  {profileData?.users?.last_name}
                </span>
              </div>
              <div className="two--row--container-grid">
                <strong> Email:</strong>
                <span>{profileData?.users?.user_email}</span>
              </div>
              <div className="two--row--container-grid">
                <strong>Phone:</strong>
                <span>{profileData?.users?.contact_number}</span>
              </div>
              <div className="two--row--container-grid">
                <strong>Mobile:</strong>
                <span>{profileData?.users?.user_mobile}</span>
              </div>
              <div className="two--row--container-grid">
                <strong>Address:</strong>
                <span>{profileData?.users?.street_address}</span>
              </div>
              <div className="two--row--container-grid">
                <strong>Bio:</strong>
                <p>{profileData?.bio}</p>
              </div>
            </div>
          </div>

          <div className="profile--setting--inner--leftright--container">
            {" "}
            {/* <CompanyProfile
              logo={profileData?.users?.company_logo}
              loading={logoLoading}
              companyImage={companyImage}
            /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                justifyContent: "center",
                width: "fit-content",
              }}
            >
              <div className="two--row--container-grid">
                <strong>Company Name:</strong>
                <span>{profileData?.users?.company_name}</span>
              </div>
              <div className="two--row--container-grid">
                <strong>Company Color:</strong>
                <span>{profileData?.users?.company_color}</span>
              </div>
              <div className="two--row--container-grid">
                <strong>Agent Color:</strong>
                <span>{profileData?.users?.agent_color}</span>
              </div>
              <div className="two--row--container-grid">
                <strong>ABN:</strong>
                <span
                  style={{ display: "flex", flexDirection: "row", gap: "1rem" }}
                >
                  {profileData?.abn}{" "}
                  {profileData?.abn_vereified_at ? (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.5rem",
                        color: "green",
                      }}
                    >
                      <VscVerified
                        style={{ color: "green", fontSize: "1.2rem" }}
                      />{" "}
                      Verified
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "0.5rem",
                        color: "red",
                      }}
                    >
                      <GoUnverified
                        style={{ color: "red", fontSize: "1.2rem" }}
                      />
                      Unverified{" "}
                    </span>
                  )}{" "}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            margin: "1rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <p style={{ fontSize: "1.5rem" }}>Image Gallery</p>
          {profileData?.lead_profile?.images?.length == 0 && <Empty />}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            {profileData?.lead_profile?.images?.map((data) => (
              <img
                src={data?.image}
                alt="srcgallery"
                style={{
                  width: "12rem",
                  height: "12rem",
                  objectFit: "contain",
                }}
              />
            ))}
          </div>
          {/* <Table
            columns={columns}
            dataSource={profileData?.lead_profile?.documents}
            pagination={false}
          /> */}
        </div>
        <div
          style={{
            margin: "1rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <p style={{ fontSize: "1.5rem" }}>Documents</p>
          <Table
            columns={columns}
            dataSource={profileData?.lead_profile?.documents}
            pagination={false}
          />
        </div>
        <div
          style={{
            margin: "1rem 0",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <p style={{ fontSize: "1.5rem" }}>References</p>
          <Table
            columns={referenceColumn}
            dataSource={profileData?.lead_profile?.references}
            pagination={false}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(Profile);

export const ProfilePicture = ({ profile, loading, profileImage }) => {
  const dispatch = useDispatch();
  const profileRef = useRef(null);

  const handleUploadImage = (e) => {
    const form = new FormData();
    form.append("files", e.target.files[0]);

    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_image",
        attempt: "PROFILE_UPLOAD_REQUEST_ATTEMPT",
        success: "PROFILE_UPLOAD_REQUEST_SUCCESS",
        failure: "PROFILE_UPLOAD_REQUEST_FAILURE",

        multipartFormData: true,
        saveBearerToken: false,
        successInternalState: () => {},
        failureInternalState: () => {},
      })
    );
  };

  return (
    <div style={{ background: "#F5F5F5" }}>
      {loading && <MainLoader />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <input
              ref={profileRef}
              style={{ display: "none" }}
              type="file"
              accept=".jpg , .png , .web"
              onChange={handleUploadImage}
            />
            {profileImage ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="profile--imagecontainer">
                  <img
                    style={{
                      height: "8rem",
                      width: "8rem",
                      borderRadius: "0.5rem",
                      border: "4px solid #E8E2E2",
                      objectFit: "contain",
                    }}
                    src={profileImage}
                    alt="img"
                  />
                </div>

                <div className="profile--setting--profile--image">
                  <Button
                    onClick={() => profileRef.current.click()}
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: "3rem",
                      fontWeight: "800",
                      border: "3px solid #F99417",
                      color: "#F99417",
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div className="profile--imagecontainer">
                  <FileImageOutlined
                    style={{
                      fontSize: "8rem",
                      color: "#c0c2c993",
                    }}
                  />
                </div>
                <div className="profile--setting--profile--image">
                  <Button
                    onClick={() => profileRef.current.click()}
                    style={{
                      backgroundColor: "transparent",
                      borderRadius: "3rem",
                      fontWeight: "600",
                      color: "#3AA6B9",
                      border: "2px solid #3AA6B9",
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CompanyProfile = ({ logo, loading, companyImage }) => {
  const dispatch = useDispatch();
  const logoRef = useRef(null);

  const handleUploadLogo = (e) => {
    const form = new FormData();
    form.append("files", e.target.files[0]);
    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_company_logo",
        attempt: "CLOGO_UPLOAD_REQUEST_ATTEMPT",
        success: "CLOGO_UPLOAD_REQUEST_SUCCESS",
        failure: "CLOGO_UPLOAD_REQUEST_FAILURE",

        multipartFormData: true,
        saveBearerToken: false,
        successInternalState: () => {},
        failureInternalState: () => {},
      })
    );
  };

  return (
    <div style={{ background: "#F5F5F5" }}>
      {loading && <MainLoader />}
      <div>
        <div className="company--logo--container">
          <input
            ref={logoRef}
            onChange={handleUploadLogo}
            style={{ display: "none" }}
            type="file"
            accept=".jpg , .png , .jpeg"
          />
          <div className="company--logo--subcontainer">
            {companyImage ? (
              <>
                <img
                  className="temp--company--logo"
                  alt="logo"
                  src={companyImage}
                  style={{
                    border: "4px solid #E8E2E2",
                    objectFit: "contain",
                  }}
                />

                <Button
                  onClick={() => logoRef.current.click()}
                  className="company--logo--uploadbutton"
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: "3rem",
                    fontWeight: "800",
                    border: "3px solid #F99417",
                    color: "#F99417",
                  }}
                >
                  Upload
                </Button>
              </>
            ) : (
              <>
                <FileImageOutlined
                  className="temp--company--logo"
                  style={{
                    fontSize: "8rem",
                    color: "#c0c2c993",
                  }}
                />
                <Button
                  onClick={() => logoRef.current.click()}
                  className="company--logo--uploadbutton"
                  style={{
                    backgroundColor: "transparent",
                    borderRadius: "3rem",
                    fontWeight: "800",
                    border: "3px solid #F99417",
                    color: "#F99417",
                  }}
                >
                  Upload
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const PasswordComponent = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const validatePassword = (_, value) => {
    const password = form.getFieldValue("password");
    if (value && password && value !== password) {
      return Promise.reject("Passwords do not match");
    }
    return Promise.resolve();
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpdatePassword = (values) => {
    dispatch(
      allActions(values, {
        method: "post",
        endPoint: "user_update_password",
        attempt: "UPDATE_PASSWORD_REQUEST_ATTEMPT",
        success: "UPDATE_PASSWORD_REQUEST_SUCCESS",
        failure: "UPDATE_PASSWORD_REQUEST_FAILURE",

        saveBearerToken: false,
        successInternalState: () => {
          setIsModalOpen(false);
        },
        failureInternalState: () => {},
      })
    );
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleCancel}
      closable={false}
      footer={null}
    >
      <div>
        <h3>Change Password</h3>
        <Divider style={{ margin: "1rem 0" }} />
        <div>
          <Form
            form={form}
            labelAlign="left"
            onFinish={handleUpdatePassword}
            layout="vertical"
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Passsword is required",
                },
              ]}
              name="current_password"
              label="Current Password"
            >
              <Input placeholder="Current Password" />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "New Passsword is required",
                },
              ]}
              name="password"
              label="New Password"
            >
              <Input placeholder="New Password" />
            </Form.Item>
            <Form.Item
              dependencies={["password"]}
              rules={[
                { required: true, message: "Please confirm your password" },
                { validator: validatePassword },
              ]}
              name="password_confirm"
              label="Confirm Password"
            >
              <Input placeholder="Confirm Password" />
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
                marginTop: "1rem",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button danger onClick={handleOk}>
                Cancel{" "}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="button--modal--primary"
              >
                Update
              </Button>{" "}
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};
