import React, { useState } from "react";
import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Tabs } from "antd";
import ReviewComponent from "./ReviewComponent";
import ReviewedComponent from "./ReviewedComponent";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "My Reviews",
    link: "/my-reviews",
  },
];

const items = [
  {
    key: "0",
    label: "Review",
  },
  {
    key: "1",
    label: "Reviewed",
  },
];

export default function MyReviews() {
  const [current, setCurrent] = useState(0);
  const activeComponent = [, <ReviewedComponent />];
  return (
    <DashboardLayout>
      <Breadcrumb title="My reviews" crumbList={crumbList} />
      {/* <Tabs
        size="large"
        defaultActiveKey={current}
        items={items}
        onChange={(value) => {
          setCurrent(value);
        }}
      />
      {activeComponent[current]} */}
      <ReviewComponent />
    </DashboardLayout>
  );
}
