import React, { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";

import { allActions } from "../../Redux/myActions";
import UseCatBhook from "../customHook/useCatBhook";
import UseCatChook from "../customHook/useCatChook";
import MainLoader from "../loader/MainLoader";
import { Divider } from "antd";

const mapStateToProps = (store) => {
  const PropertyReducer = store?.PropertyReducer;
  return { PropertyReducer };
};

const PostAnAdDrawer = ({ PropertyReducer, setCurrentState }) => {
  const { catB, loading } = PropertyReducer;
  const [catBid, setCatBid] = useState();
  const [catBname, setCatBname] = useState("");
  const [catCid, setCatCid] = useState();
  const [catCname, setCatCname] = useState("");
  const [catDid, setCatDid] = useState();

  const dispatch = useDispatch();

  const catC = UseCatBhook(catBid);
  const catD = UseCatChook(catCid);

  useEffect(() => {
    setCatCid("");
    setCatDid("");
    setCatCname("");
  }, [catBid]);

  useEffect(() => {
    setCatDid("");
  }, [catCid]);

  const handleSelectCatB = (item) => {
    setCatBid(item?.cat_id);
    localStorage.setItem("cat2", item?.cat_id);
    setCatBname(item?.cat_name);
  };

  const handleSubmit = () => {
    const cat1 = localStorage.getItem("cat1");
    const cat2 = localStorage.getItem("cat2");
    const cat3 = localStorage.getItem("cat3");
    const cat4 = localStorage.getItem("cat4");

    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `ads_form?cat1=${cat1}&cat2=${cat2}&cat3=${cat3}&cat4=${cat4}`,
          attempt: "AD_PROPERTY_REQUEST_ATTEMPT",
          success: "AD_PROPERTY_REQUEST_SUCCESS",
          failure: "AD_PROPERTY_REQUEST_FAILURE",

          isToken: false,
          saveBearerToken: false,
          successInternalState: () => {
            // setCurrentState(true);
            setCurrentState("Ad Details");
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div className="realEstate--category--layout">
      <div className="realEstate--category--layout--header ad--property--container">
        <h3 style={{ paddingLeft: "1rem", height: "fit-content" }}>
          Select Category
        </h3>
        <div className="hr--line"></div>
        <div className="realEstate--category--container">
          <div className="realEstate--header">
            <h3>Real Estate</h3>
            <div className="realEstate--header--content">
              {catB &&
                catB?.map((item) => {
                  return (
                    <div
                      key={item?.cat_id}
                      className={
                        item?.cat_id === catBid
                          ? "button--modal"
                          : "unchecked--status"
                      }
                      onClick={() => handleSelectCatB(item)}
                    >
                      {item?.cat_name}
                    </div>
                  );
                })}
            </div>
          </div>
          {catC && (
            <div className="realEstate--body">
              <h3>{catBname}</h3>
              <div className="realEstate--body--content">
                {catC?.map((item) => (
                  <div
                    key={item?.cat_id}
                    className={
                      item?.cat_id === catCid
                        ? "button--modal"
                        : "unchecked--status"
                    }
                    onClick={() => {
                      setCatCid(item?.cat_id);
                      setCatCname(item?.cat_name);
                      localStorage.setItem("cat3", item?.cat_id);
                    }}
                  >
                    {item?.cat_name}
                  </div>
                ))}
              </div>
            </div>
          )}

          {catD && (
            <div className="realEstate--footer">
              <h3>{catCname}</h3>
              <div className="realEstate--body--content">
                {catD?.map((item) => {
                  if (
                    item?.cat_name === "I'm offering" ||
                    item?.cat_name === "I'm offering a rental property" ||
                    item?.cat_name === "I'm offering a property for sale"
                  ) {
                    return (
                      <div
                        key={item?.cat_id}
                        className={
                          item?.cat_id === catDid
                            ? "button--modal"
                            : "unchecked--status"
                        }
                        onClick={() => {
                          setCatDid(item?.cat_id);
                          localStorage.setItem("cat4", item?.cat_id);
                        }}
                      >
                        {item?.cat_name}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          )}
        </div>
      </div>
      {catDid && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            padding: "1rem",
            alignItems: "center",
          }}
        >
          <span style={{ fontSize: "1rem" }}>
            You have selected
            <span className="style--estateType"> {catCname}</span>
          </span>
          <button
            style={{ border: "none" }}
            onClick={handleSubmit}
            className="button--modal--primary"
          >
            Go
          </button>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(PostAnAdDrawer);
