import { Button, Checkbox, Descriptions, Divider } from "antd";
import React from "react";

export default function PreviewAdDetails({ formData, setCurrentState }) {
  const options = [
    {
      label: "Classiestate.com.au ",
      value: "Classiestate.com.au ",
    },
    {
      label: "Realestate.com.au ",
      value: "Realestate.com.au ",
    },
    {
      label: "domain.com.au ",
      value: "domain.com.au ",
    },
    {
      label: "rent.com.au ",
      value: "rent.com.au ",
    },
    {
      label: "homely.com.au ",
      value: "homely.com.au homely.com.au homely.com.au ",
    },
  ];

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        margin: "1rem 4rem",
        gap: "1rem",
      }}
    >
      {prevItemDetail("Property Type", formData?.property_type)}
      {prevItemDetail("Property State", formData?.property_state)}
      {prevItemDetail("Property Suburb", formData?.property_suburb)}
      {prevItemDetail("Property StreetName", formData?.property_street_name)}
      {prevItemDetail("Property House No.", formData?.property_house_number)}
      {prevItemDetail("Property Postcode.", formData?.property_postcode)}
      <Divider style={{ gridColumn: "span 4", margin: "0.5rem 0" }} />
      {prevItemDetail("Property Condition", formData?.Property_condition)}
      {prevItemDetail("Sell Type", formData?.sell_type)}
      {prevItemDetail(
        "Price Type",
        formData?.price_type === "1" ? "Fixed" : "Price Range"
      )}
      <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
        {prevItemDetail(
          "Price",
          ` ${formData?.ad_price} AUD ${
            formData?.price_type === "2" && `- ${formData?.to_ad_price} AUD`
          }`
        )}{" "}
      </div>
      {prevItemDetail(
        "Payment Duration Type",
        formData?.price_type2 === "Week"
          ? "Weekly"
          : formData?.price_type2 === "Month"
          ? "Monthly"
          : "N/A"
      )}
      {prevItemDetail("Security Bond", formData?.security_bound)}
      {prevItemDetail("Available From", formData?.ticket_start_date)}
      {prevItemDetail("Land Area", formData?.area)}
      {prevItemDetail("House Area", formData?.house_area)}
      {prevItemDetail("No of Rooms", formData?.no_room)}
      {prevItemDetail("No of Bathrooms", formData?.Number_of_Bathrooms)}
      {prevItemDetail("No of Car space", formData?.no_of_car_space)}
      {prevItemDetail("Parking Type", formData?.Parking)}
      <Divider style={{ gridColumn: "span 4", margin: "0.5rem 0" }} />
      {prevItemDetail("Property Title", formData?.ad_title)}
      <div style={{ gridColumn: "span 3" }}>
        {prevItemDetail("Property Description", formData?.description)}
      </div>
      <Divider style={{ gridColumn: "span 4", margin: "0.5rem 0" }} />
      <div style={{ gridColumn: "span 4", fontSize: "1.2rem" }}>
        Contact Details
      </div>
      {prevItemDetail("Full name", formData?.name)}{" "}
      {prevItemDetail("Email", formData?.email)}
      {prevItemDetail("Phone", formData?.phone)}
      {prevItemDetail("Address", formData?.address)}
      <Divider style={{ gridColumn: "span 4", margin: "0.5rem 0" }} />
      <Checkbox.Group size="large" options={options} />
      <Divider style={{ gridColumn: "span 4", margin: "0.5rem 0" }} />
      <div
        style={{
          gridColumn: "span 4",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button type="primary">Add New Property</Button>
      </div>
    </div>
  );
}

export const prevItemDetail = (label, value) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
      }}
    >
      <span style={{ color: "black" }}>{label}:</span>
      <span style={{ color: "black", fontWeight: "bold" }}>
        {value ? value : "N/A"}
      </span>
    </div>
  );
};
