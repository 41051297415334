import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import { useDispatch, connect } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import MainLoader from "../../component/loader/MainLoader";
import { formatDateFunction } from "../../Helpers/FrontendHelper";
import moment from "moment";

const mapStateToProp = (store) => {
  const { PropertyReducer } = store;

  return { PropertyReducer };
};

const SubscriptionListComponent = ({ PropertyReducer }) => {
  const dispatch = useDispatch();
  const { paymentList, loading } = PropertyReducer;

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "inquiry_credit_subscription_list",
          attempt: "FETCH_PAYMENT_DATA_REQUEST_ATTEMPT",
          success: "FETCH_PAYMENT_DATA_REQUEST_SUCCESS",
          failure: "FETCH_PAYMENT_DATA_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);
  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Payment",
      link: "/payment",
    },
  ];
  return (
    <div className="payment--container">
      {/* <PaymentSearchComponent /> */}
      {paymentList && (
        <PaymentListComponent paymentList={paymentList?.results} />
      )}
    </div>
  );
};

export default connect(mapStateToProp)(SubscriptionListComponent);

export const PaymentSearchComponent = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSearchPayment = (values) => {
    if (values?.from_date || values?.to_date || values?.payment_method) {
      const fromDate = formatDateFunction(values?.from_date);
      const toDate = formatDateFunction(values?.to_date);

      dispatch(
        allActions(
          {},
          {
            method: "get",
            endPoint: `payment_history?${
              fromDate ? `from_date=${fromDate}&` : ""
            }${toDate ? `to_date=${toDate}&` : ""}${
              values?.payment_method
                ? `payment_method=${values?.payment_method}`
                : ""
            }`,
            attempt: "FETCH_PAYMENT_DATA_REQUEST_ATTEMPT",
            success: "FETCH_PAYMENT_DATA_REQUEST_SUCCESS",
            failure: "FETCH_PAYMENT_DATA_REQUEST_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {
              form.resetFields();
            },
            failureInternalState: () => {},
          }
        )
      );
    } else {
      message.warning("Search Field shouldn't be Empty");
    }
  };

  return (
    <div className="payment--container--header">
      <Form
        form={form}
        onFinish={handleSearchPayment}
        className="payment--header--inner--container"
      >
        <Form.Item
          style={{ margin: "0" }}
          name="from_date"
          className="payment--field--wrapper"
        >
          <DatePicker
            className="date--picker--payment"
            size="large"
            placeholder="From Date"
            format="DD-MM-YYYY"
          />
        </Form.Item>
        <Form.Item
          style={{ margin: "0" }}
          name="to_date"
          className="payment--field--wrapper"
        >
          <DatePicker
            className="date--picker--payment"
            size="large"
            placeholder="To Date"
            format="DD-MM-YYYY"
          />
        </Form.Item>
        <Form.Item
          style={{ margin: "0" }}
          name="payment_method"
          className="payment--field--wrapper"
        >
          <Select
            bordered={false}
            className="date--picker--payment"
            size="large"
            placeholder="Payment Method"
            options={[
              {
                label: "Stripe",
                value: "Stripe",
              },
              {
                label: "Classi Credit",
                value: "Classi Credit",
              },
            ]}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          shape="round"
          className="button--modal"
          style={{
            borderColor: "white",
            borderRadius: "0.5rem 2rem 2rem 0.5rem ",
          }}
          size="large"
        >
          Search
        </Button>
      </Form>
    </div>
  );
};

export const PaymentListComponent = ({ paymentList }) => {
  const handlePDFLinkClick = (event, payment_pdf) => {
    if (payment_pdf?.length > 0) {
      event.preventDefault();
      window.open(payment_pdf, "_blank");
    } else {
      message.warning("No payment Slip");
    }
  };

  const columns = [
    {
      title: "Subscription Type",
      align: "center",
      dataIndex: "subscription_type",
    },
    {
      title: "Amount(AUD)",
      align: "center",
      dataIndex: "price",
      key: "price",
    },

    {
      title: "Invoice Status",
      align: "center",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    {
      title: "Payment Method",
      align: "center",
      dataIndex: "payment_method",
      key: "payment_method ",
    },
    {
      title: "Date",
      align: "center",
      key: "payment_date",
      dataIndex: "payment_date",
      render: (date) => <span>{moment(date)?.format("DD-MM-YYYY")}</span>,
    },
  ];
  return (
    <div className="payment--container--body">
      <Table
        bordered
        scroll={{ y: 400, x: 800 }}
        pagination={false}
        dataSource={paymentList}
        columns={columns}
      />
    </div>
  );
};
