import {
  Button,
  Col,
  ColorPicker,
  Form,
  Input,
  InputNumber,
  Space,
} from "antd";
import React, { useMemo, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { validateInputNumber } from "../../../Helpers/FrontendHelper";

export default function PersonalInformation({
  formData,
  setFormData,
  setCurrent,
}) {
  const [form] = Form.useForm();

  const [formatHex, setFormatHex] = useState("hex");
  const [companyColorHex, setCompanyColorHex] = useState(
    formData?.company_color
  );
  const [fontColorHex, setFontColorHex] = useState(formData?.font_color);
  const [agentColorHex, setAgentColorHex] = useState(formData?.agent_color);

  const companyHexString = useMemo(
    () =>
      typeof companyColorHex === "string"
        ? companyColorHex
        : companyColorHex?.toHexString(),
    [companyColorHex]
  );

  const agentHexString = useMemo(
    () =>
      typeof agentColorHex === "string"
        ? agentColorHex
        : agentColorHex?.toHexString(),
    [agentColorHex]
  );

  const fontHexString = useMemo(
    () =>
      typeof fontColorHex === "string"
        ? fontColorHex
        : fontColorHex?.toHexString(),
    [fontColorHex]
  );

  const handleUpdateProfileInformation = (values) => {
    setFormData((prev) => ({
      ...prev,
      ...values,
      agent_color: agentHexString,
      font_color: fontHexString,
      company_color: companyHexString,
    }));
    setCurrent((prev) => prev + 1);
  };

  return (
    <Form
      initialValues={{
        email: formData?.email,
        first_name: formData?.first_name,
        last_name: formData?.last_name,
        phone: formData?.phone,
        mobile: formData?.mobile,
        company_name: formData?.company_name,
        company_color: formData?.company_color ? formData?.company_color : null,
        agent_color: formData?.agent_color ? formData?.agent_color : "",
        address: formData?.address,
        font_color: formData?.font_color,
        abn: formData?.abn,
        bio: formData?.bio,
        fontColorHex: formData?.fontColorHex,
        license_number: formData?.license_number,
      }}
      layout="vertical"
      onFinish={handleUpdateProfileInformation}
      form={form}
    >
      <div className="two--row--container">
        <Form.Item
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Company Name is required",
            },
          ]}
          className="two--row--child--container"
          label="Company Name"
          name="company_name"
        >
          <Input placeholder="Enter Company Name" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="two--row--child--container"
          label="Company Color"
          name="company_color"
        >
          <Space>
            <Col>
              <ColorPicker
                format={formatHex}
                onChange={setCompanyColorHex}
                onFormatChange={() => setFormatHex("hex")}
              />
            </Col>
            <Col>
              HEX: <span>{companyHexString}</span>
            </Col>
          </Space>
        </Form.Item>
      </div>
      <div className="two--row--container">
        <Form.Item
          style={{ width: "100%" }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Email is required",
            },
          ]}
          className="two--row--child--container"
          label="Email"
          name="email"
        >
          <Input readOnly type="email" placeholder="Enter an Email" />
        </Form.Item>

        <Form.Item
          style={{ width: "100%" }}
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "ABN is required",
            },
          ]}
          className="two--row--child--container"
          label="ABN"
          name="abn"
        >
          <InputNumber
            style={{ width: "100%" }}
            type="number"
            placeholder="Enter ABN"
          />
        </Form.Item>
        <Form.Item
          style={{ width: "100%" }}
          labelAlign="left"
          className="two--row--child--container"
          label="License number"
          name="license_number"
        >
          <InputNumber
            style={{ width: "100%" }}
            type="number"
            placeholder="Enter License No"
          />
        </Form.Item>
      </div>

      <Form.Item label="Address" name="address" style={{ width: "100%" }}>
        <Autocomplete
          apiKey="AIzaSyAKLUx_rnltQ2u9Xr39DcpX3UdRr293gCU"
          style={{
            width: "100%",
            outline: "none",
            height: "2rem",
            border: "1px solid #D8D9DA",
            padding: "0 0.5rem",
          }}
          onPlaceSelected={(place) => {
            form.setFieldsValue({ address: place?.formatted_address });
          }}
          options={{
            types: ["address"],
            componentRestrictions: { country: "au" },
          }}
        />
      </Form.Item>
      {/* </div> */}

      <div className="two--row--container">
        <Form.Item
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "First Name is required",
            },
          ]}
          className="two--row--child--container"
          label="First Name"
          name="first_name"
        >
          <Input placeholder="First Name" />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Last Name is required",
            },
          ]}
          className="two--row--child--container"
          label="Last Name"
          name="last_name"
        >
          <Input placeholder="Last Name" />
        </Form.Item>
      </div>
      <div className="two--row--container">
        <Form.Item
          labelAlign="left"
          rules={[
            {
              required: true,
              message: "Phone Number is required",
            },

            { validator: validateInputNumber },
          ]}
          className="two--row--child--container"
          label="Phone Number"
          name="phone"
        >
          <InputNumber
            type="number"
            style={{ width: "100%" }}
            placeholder="Enter Phone Number"
          />
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="two--row--child--container"
          label="Mobile Number"
          name="mobile"
          rules={[{ validator: validateInputNumber }]}
        >
          <InputNumber
            type="number"
            style={{ width: "100%" }}
            placeholder="Enter Mobile Number"
          />
        </Form.Item>
      </div>
      <div className="two--row--container">
        <Form.Item
          labelAlign="left"
          className="two--row--child--container"
          label="Agent Color"
          name="agent_color"
        >
          <Space>
            <Col>
              <ColorPicker
                format={formatHex}
                onChange={setAgentColorHex}
                onFormatChange={() => setFormatHex("hex")}
              />
            </Col>
            <Col>
              HEX: <span>{agentHexString}</span>
            </Col>
          </Space>{" "}
        </Form.Item>
        <Form.Item
          labelAlign="left"
          className="two--row--child--container"
          label="Text Color"
          name="font_color"
        >
          <Space>
            <Col>
              <ColorPicker
                format={formatHex}
                onChange={setFontColorHex}
                onFormatChange={() => setFormatHex("hex")}
              />
            </Col>
            <Col>
              HEX: <span>{fontHexString}</span>
            </Col>
          </Space>{" "}
        </Form.Item>
      </div>
      <Form.Item label="Bio" name="bio">
        <Input.TextArea rows={6} />
      </Form.Item>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          width: "100%",
        }}
        className="edit--profile--button"
      >
        <Button className="button--modal" type="primary" htmlType="submit">
          Next
        </Button>
      </div>
    </Form>
  );
}
