import { CheckOutlined } from "@ant-design/icons";
import { Button, Divider, Segmented, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";

export default function SubscriptionElement({
  setCurrent,
  subscriptionForm,
  setSubscriptionForm,
}) {
  const dispatch = useDispatch();
  const { subscriptionCreditData } = useSelector(
    (store) => store?.AgentAgencyReducer
  );
  const [alignValue, setAlignValue] = useState("Weekly");
  const [packagePrice, setPackagePrice] = useState();
  const [subType, setSubType] = useState();

  console.log("checking checking", subscriptionCreditData);

  useEffect(() => {
    subscriptionCreditData &&
      setPackagePrice(subscriptionCreditData?.weekly_package?.price);

    subscriptionCreditData &&
      setSubType(subscriptionCreditData?.weekly_package?.variable_name);
  }, [subscriptionCreditData]);

  useEffect(() => {
    fetchSubscriptionPlan();
  }, []);

  const fetchSubscriptionPlan = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "enquiry_credit_subscription",
          attempt: "FETCH_ENQUIRY_SUBSCRIPTION_ATTEMPT",
          success: "FETCH_ENQUIRY_SUBSCRIPTION_SUCCESS",
          failure: "FETCH_ENQUIRY_SUBSCRIPTION_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "2rem 1.5rem",
        borderRadius: "1rem",
        border: "1px solid #cfcfcf80",
        justifyContent: "center",
        gap: "1.5rem",
        alignItems: "center",
        maxWidth: "22rem",
      }}
    >
      <h1 style={{ color: "green", fontSize: "1.5rem" }}> Subscription</h1>

      <Segmented
        size="large"
        defaultValue={alignValue}
        style={{
          borderRadius: "0.5rem",
          width: "19rem",
        }}
        onChange={(value) => {
          setAlignValue(value);
          if (value === "Weekly") {
            setPackagePrice(subscriptionCreditData?.weekly_package?.price);
            setSubType(subscriptionCreditData?.weekly_package?.variable_name);
          } else if (value === "Monthly") {
            setPackagePrice(subscriptionCreditData?.monthly_package?.price);
            setSubType(subscriptionCreditData?.monthly_package?.variable_name);
          } else if (value === "Yearly") {
            setPackagePrice(subscriptionCreditData?.yearly_package?.price);
            setSubType(subscriptionCreditData?.yearly_package?.variable_name);
          }
        }}
        options={["Weekly", "Monthly", "Yearly"]}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          gap: "1rem",
        }}
      >
        <p
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <h1 style={{ color: "black", fontSize: "1.5rem" }}>
            {" "}
            ${packagePrice}/
          </h1>
          <p>{alignValue}</p>
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "0.5rem",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          <CheckOutlined style={{ color: "green" }} />
          <p>
            {alignValue === "Weekly"
              ? subscriptionCreditData?.weekly_package_content?.article_title
              : alignValue === "Monthly"
              ? subscriptionCreditData?.monthly_package_content?.article_title
              : alignValue === "Yearly"
              ? subscriptionCreditData?.yearly_package_content?.article_title
              : "N/A"}
          </p>
        </div>
      </div>
      <Button
        size="default"
        type="primary"
        style={{ width: "100%", borderRadius: "1rem !important" }}
        onClick={() => {
          setSubscriptionForm((prev) => ({
            ...prev,
            packagePrice: packagePrice,
            subscription_type: subType,
          }));
          setCurrent((prev) => prev + 1);
        }}
      >
        Get Started
      </Button>
    </div>
  );
}
