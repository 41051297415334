import React, { useState } from "react";
import SubscriptionElement from "../../component/pacakgeComponent/SubscriptionElement";
import { Divider, Steps } from "antd";
import PaymentElement from "../../component/pacakgeComponent/PaymentElement";
import SuccessElement from "../../component/pacakgeComponent/SuccessElement";
import { useSelector } from "react-redux";
import MainLoader from "../../component/loader/MainLoader";

export default function SubscriptionComponent() {
  const { loading } = useSelector((store) => store?.AgentAgencyReducer);
  const [current, setCurrent] = useState(0);
  const [subscriptionForm, setSubscriptionForm] = useState({
    packagePrice: null,
    paymentMethod: "stripe",
    email: localStorage.getItem("agEmail"),
    cardName: "",
    cardNumber: null,
    expDate: null,
    cvcNumber: null,
    subscription_type: null,
  });
  const subscriptionModal = [
    <SubscriptionElement
      setCurrent={setCurrent}
      subscriptionForm={subscriptionForm}
      setSubscriptionForm={setSubscriptionForm}
    />,
    <PaymentElement
      setCurrent={setCurrent}
      subscriptionForm={subscriptionForm}
      setSubscriptionForm={setSubscriptionForm}
    />,
    <SuccessElement setCurrent={setCurrent} />,
  ];
  return (
    <div
      style={{
        background: "white",
        padding: "2rem 1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Steps
        style={{ padding: "0 2rem" }}
        current={current}
        items={[
          {
            title: "Subscription Plan",
          },
          {
            title: "Payment Details",
          },
          {
            title: "Complete",
          },
        ]}
      />
      <Divider />
      {subscriptionModal[current]}
    </div>
  );
}
