import { Button, Form, Input, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { Checkbox, Divider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../Redux/myActions";
import { useNavigate } from "react-router-dom";

export default function AddServiceModal({ openModal, setOpenModal }) {
  const CheckboxGroup = Checkbox.Group;
  const { leadCatList } = useSelector((store) => store?.ProfileReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [catList, setCatList] = useState([]);
  const [catId, setCatId] = useState([]);

  useEffect(() => {
    setCatList(leadCatList);
  }, [leadCatList]);

  const handleSubmitLeadCat = (values) => {
    dispatch(
      allActions(
        {
          ...values,
          code: localStorage.getItem("code"),
          user_id: localStorage.getItem("userId"),
        },
        {
          method: "post",
          endPoint: "save_lead_categories",
          attempt: "SAVE_LEAD_CAT_REQUEST_ATTEMPT",
          success: "SAVE_LEAD_CAT_REQUEST_SUCCESS",
          failure: "SAVE_LEAD_CAT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            message.success("Successfully added lead category");
            setTimeout(() => {
              navigate("/");
            }, 1500);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleSearchInquiry = (e) => {
    const value = e.target.value.trim();
    value
      ? setCatList([
          ...leadCatList?.filter((category) =>
            category?.cat_name?.toLowerCase()?.includes(value?.toLowerCase())
          ),
        ])
      : setCatList(leadCatList);
  };

  return (
    <Modal
      style={{
        top: 20,
      }}
      open={openModal}
      closable={false}
      footer={false}
      width={800}
    >
      <h1 style={{ textAlign: "center" }}>Select the inquiry service</h1>
      <Input
        style={{ margin: "1rem 0" }}
        onChange={handleSearchInquiry}
        placeholder="Search Inquiry Category"
      />
      <Form onFinish={handleSubmitLeadCat}>
        <Form.Item
          name="categories"
          rules={[{ required: true, message: "Select lead category" }]}
        >
          <CheckboxGroup
            value={catId}
            style={{
              display: "grid",
              margin: "1rem 0",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "1rem",
              width: "100%",
              maxHeight: "75vh",
              overflowY: "scroll",
              background: "#cfcfcf10",
            }}
            onChange={(value) => setCatId(value)}
          >
            {catList?.map((lead) => (
              <Checkbox value={lead?.cat_id}>{lead?.cat_name}</Checkbox>
            ))}
          </CheckboxGroup>
        </Form.Item>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button style={{ alignSelf: "end" }} type="primary" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
