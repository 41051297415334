import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import store from "./Redux/store";
import "./index.css";
import "./scss/my-app.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="660338474435-242ofq0e118rvlgrnkkpa3fn977ousdf.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
    ;
  </Provider>
);
