import React from "react";
import { Form, Input } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { connect, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";

import logo3 from "../../assets/classileads.png";

const mapStateToProps = (store) => {
  const AuthReducer = store?.AuthReducer;
  return AuthReducer;
};

const Forgetpassword = (AuthReducer) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = AuthReducer;

  const handleSubmitForgotEmail = (values) => {
    dispatch(
      allActions(
        { email: values.email },
        {
          method: "post",
          endPoint: "forgot_password",
          attempt: "FORGOTPASSWORD_OTP_REQUEST_ATTEMPT",
          success: "FORGOTPASSWORD_OTP_REQUEST_SUCCESS",
          failure: "FORGOTPASSWORD_OTP_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            navigate("/auth/change-password", { state: { isSuccess: true } });
            localStorage.setItem("userEmail", values.email);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div className="forgotpass--container">
      <div className="forgotpass--container--body">
        {loading && <LoaderPrimary />}

        <Form
          onFinish={handleSubmitForgotEmail}
          className="forgotpass--form--container"
        >
          <NavLink style={{ cursor: "pointer" }} to="/">
            <img
              src={logo3}
              style={{
                padding: "0.3rem",
                width: "300px",
                height: "auto",
              }}
              alt="classiEstate logo"
            />
          </NavLink>
          <p className="login--header">Forgot Password?</p>
          <Form.Item
            className="login--input"
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>Email is required</span>
                ),
              },
            ]}
          >
            <Input
              className="login--input"
              size="large"
              type="email"
              placeholder="Email"
              prefix={<UserOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <button
            disabled={loading}
            type="submit"
            className="login--button"
            style={{ border: "0", width: "100%" }}
          >
            Continue
          </button>
        </Form>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Forgetpassword);
