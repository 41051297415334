import React, { useState } from "react";
import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Tabs } from "antd";
import SubscriptionComponent from "./SubscriptionComponent";
import CreditComponent from "./CreditComponent";

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "Packages",
    link: "/packages",
  },
];

const items = [
  {
    key: "0",
    label: "Credit",
  },
  {
    key: "1",
    label: "Subscription",
  },
];

export default function Packages() {
  const [current, setCurrent] = useState(0);
  const activeComponent = [<CreditComponent />, <SubscriptionComponent />];
  return (
    <DashboardLayout>
      <Breadcrumb title="Packages" crumbList={crumbList} />
      <Tabs
        size="large"
        defaultActiveKey={current}
        items={items}
        onChange={(value) => {
          setCurrent(value);
        }}
      />
      {activeComponent[current]}
    </DashboardLayout>
  );
}
