import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";
import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { allActions } from "../../../Redux/myActions";

export default function ImageGallary({ formData, setFormData, setCurrent }) {
  const dispatch = useDispatch();
  const galleryRef = useRef(null);
  const [isNext, setIsNext] = useState(null);
  const [fileList, setFilelist] = useState([]);
  const [galleryImage, setGalleryImage] = useState(formData?.images);

  const handleUpdateImageGallery = (values) => {
    setFormData((prev) => ({ ...prev, ...values }));
    setFormData((prev) => ({ ...prev, images: galleryImage }));
    isNext ? setCurrent((prev) => prev + 1) : setCurrent((prev) => prev - 1);
  };

  const handleUploadGalleryImage = (event) => {
    const fileList = Array?.from(event.target.files);
    const form = new FormData();
    fileList?.map((file, index) => form.append(`files[]`, file));

    dispatch(
      allActions(form, {
        method: "post",
        endPoint: "upload_lead_image",
        attempt: "IMAGE_UPLOAD_REQUEST_ATTEMPT",
        success: "IMAGE_UPLOAD_REQUEST_SUCCESS",
        failure: "IMAGE_UPLOAD_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          setGalleryImage((prev) => [
            ...prev,
            ...res?.source?.map((item) => {
              return {
                image_name: item?.image_name,
                image_thumbnail_name: item?.image_thumnail_name,
              };
            }),
          ]);
        },
        failureInternalState: () => {},
      })
    );
  };

  return (
    <Form
      layout="vertical"
      initialValues={{
        images: formData?.images,
      }}
      onFinish={handleUpdateImageGallery}
    >
      {/* <Form.Item name={"profile"} label="Profile Image">
        <Input type="file" />
      </Form.Item> */}
      <Form.Item name={"images"} label="Gallery Image">
        <input
          multiple
          ref={galleryRef}
          type="file"
          style={{ display: "none" }}
          accept=".png, .jpg, .jpeg, .gif"
          onChange={handleUploadGalleryImage}
        />{" "}
        <div
          style={{
            display: "gird",
            gridTemplateColumns: "1fr 4fr",
            gap: "1rem",
          }}
        >
          <Button onClick={() => galleryRef.current.click()} type="primary">
            Upload
          </Button>
          <div
            style={{
              display: "flex",
              gridTemplateColumns: "row",
              flexWrap: "wrap",
              gap: "0.5rem",
              margin: "1rem 0",
            }}
          >
            {galleryImage?.map((imgData) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px solid #cfcfcf",
                  padding: "0.25rem",
                  background: "#cfcfcf90",
                  borderRadius: "0.25rem",
                }}
              >
                <img
                  src={imgData?.image_name}
                  alt="gallery_image"
                  style={{
                    width: "8rem",
                    height: "auto",
                    objectFit: "content",
                  }}
                />
                <Button
                  onClick={() => {
                    setGalleryImage(
                      galleryImage?.filter(
                        (data) => data?.image_name !== imgData?.image_name
                      )
                    );
                  }}
                  type="primary"
                  danger
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        </div>
      </Form.Item>
      {/* <Form.Item name={"gallery"} label="Gallery Image">
        <Upload
          listType="picture"
          multiple={true}
          customRequest={({ file, onSuccess, onError }) => {
            const form = new FormData();
            form.append("files", file);

            dispatch(
              allActions(form, {
                method: "post",
                endPoint: "upload_image",
                attempt: "IMAGE_UPLOAD_REQUEST_ATTEMPT",
                success: "IMAGE_UPLOAD_REQUEST_SUCCESS",
                failure: "IMAGE_UPLOAD_REQUEST_FAILURE",

                multipartFormData: true,
                saveBearerToken: false,
                successInternalState: (res) => {
                  onSuccess();
                },
                failureInternalState: () => {
                  onError();
                },
              })
            );
          }}
        >
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </Form.Item> */}
      <Button.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => setIsNext(false)}
          type="primary"
          htmlType="submit"
        >
          Back
        </Button>
        <Button
          onClick={() => setIsNext(true)}
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </Button.Group>
    </Form>
  );
}
