import React, { Component, useEffect, useState } from "react";
import { Form, Input } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";

import logo3 from "../../assets/classileads.png";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const SetNewPassword = (AuthReducer) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const { loading } = AuthReducer;

  useEffect(() => {
    const isSuccess = location?.state?.isSuccess;
    !isSuccess && navigate("/");
    const userEmail = localStorage.getItem("userEmail");
    setUserEmail(userEmail);
  }, []);

  const validateConfirmPassword = (_, value) => {
    const newPassword = form.getFieldValue("newPassword");
    if (value && value !== newPassword) {
      return Promise.reject(
        new Error("Confirm Password does not match New Password.")
      );
    }
    return Promise.resolve();
  };

  const handleSubmitForgotEmail = (values) => {
    dispatch(
      allActions(
        {
          email: userEmail,
          code: values?.otp,
          password: values?.newPassword,
          password_confirm: values?.confirmPassword,
        },
        {
          method: "post",
          endPoint: "change_password",
          attempt: "CHANGE_PASSWORD_OTP_REQUEST_ATTEMPT",
          success: "CHANGE_PASSWORD_OTP_REQUEST_SUCCESS",
          failure: "CHANGE_PASSWORD_OTP_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            navigate("/");
            localStorage.removeItem("userEmail");
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div className="forgotpass--container">
      <div className="forgotpass--container--body">
        {loading && <LoaderPrimary />}

        <Form
          form={form}
          onFinish={handleSubmitForgotEmail}
          className="forgotpass--form--container"
        >
          <NavLink style={{ cursor: "pointer" }} to="/">
            <img
              src={logo3}
              style={{
                padding: "0.3rem",
                width: "300px",
                height: "auto",
              }}
              alt="classiEstate logo"
            />
          </NavLink>
          <p className="login--header">Change Password</p>
          <Form.Item
            className="login--input"
            name="otp"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>OTP is required</span>
                ),
              },
            ]}
          >
            <Input
              className="login--input"
              size="large"
              type="number"
              placeholder="Otp"
              prefix={<LockOutlined className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            className="login--input"
            name="newPassword"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    New Password is required
                  </span>
                ),
              },
            ]}
          >
            <Input.Password
              className="login--input"
              size="large"
              placeholder="New Password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
          </Form.Item>
          <Form.Item
            className="login--input"
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    Confirm password is required
                  </span>
                ),
              },
              { validator: validateConfirmPassword },
            ]}
          >
            <Input.Password
              className="login--input"
              size="large"
              type="text"
              placeholder="Confirm Password"
              prefix={<LockOutlined className="site-form-item-icon" />}
              visibilityToggle={{
                visible: confirmPasswordVisible,
                onVisibleChange: setConfirmPasswordVisible,
              }}
            />
          </Form.Item>
          <button
            disabled={loading}
            type="submit"
            // className="newpassword--button"
            className="login--button"
            style={{ border: "0", width: "100%" }}
          >
            Change Password
          </button>
        </Form>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(SetNewPassword);
