import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AreaChartOutlined,
  SettingOutlined,
  UserOutlined,
  StarOutlined,
  HomeOutlined,
  MailOutlined,
  CreditCardOutlined,
  LockOutlined,
  PlusSquareOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  BellOutlined,
  MenuOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  Spin,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { allActions } from "../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import { BiMessageAlt } from "react-icons/bi";
import { MdPayments } from "react-icons/md";
import { SiCodereview } from "react-icons/si";
import { FcSupport } from "react-icons/fc";

import logo2 from "../assets/classileads.png";
import logo3 from "../assets/classileads.png";
import logo from "../assets/classileads.png";
import { LuMessagesSquare } from "react-icons/lu";

import useNotificationHook from "../hooks/useNotificationHook";
import axios from "axios";
import { getCookie } from "../Helpers/FrontendHelper";

const { Header, Sider, Content } = Layout;
const link = [
  { propertyStatus: "all" },
  { propertyStatus: "featured" },
  { propertyStatus: "active" },
  { propertyStatus: "inactive" },
];

const mapStateToProps = (store) => {
  const { ProfileReducer } = store;
  return { ProfileReducer };
};

// const is_agent = localStorage.getItem("is_agent");

const DashboardLayout = ({ children, ProfileReducer }) => {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openNoti, setOpenNoti] = useState(false);
  const [activeNavbar, setActiveNavbar] = useState();

  const [openDrawer, setOpenDrawer] = useState(false);
  const {
    notificationList,
    notificationloading,
    profileImage,
    profileData,
    is_agent,
  } = ProfileReducer;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile_new",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  //notification
  const [notification, setNotification] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const observer = useRef();
  const [toggle, setToggle] = useState(false);

  useNotificationHook(setNotification, pageNumber, toggle);

  const lastNotificationRef = useCallback(
    (node) => {
      observer.current && observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && notificationList?.has_next_page) {
          setPageNumber((prev) => prev + 1);
        }
      });
      node && observer?.current?.observe(node);
    },
    [notificationList?.has_next_page]
  );

  const link = [
    { propertyStatus: "all" },
    { propertyStatus: "featured" },
    { propertyStatus: "active" },
    { propertyStatus: "inactive" },
  ];

  const handleNotificationClick = async (data) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "seen_notifications?api_from=CLASSILEAD",
          attempt: "SEEN_NOTIFICATION_REQUEST_ATTEMPT",
          success: "SEEN_NOTIFICATION_REQUEST_SUCCESS",
          failure: "SEEN_NOTIFICATION_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            setToggle((prev) => !prev);
          },
          failureInternalState: () => {},
        }
      )
    );
    switch (data?.notification_type) {
      case "ADD_ADS":
        navigate("/my-property/all");
        break;
      case "find_agent_equiry":
        navigate("/find-agent-inquiry/inquiry-list");
        break;
      case "OFFER":
        navigate("/offer-message/offer-received");
        break;
      case "OFFER_REPLY":
        const record = data?.notification_type_id?.split("#");
        navigate("/offer-message/offer-received");
        break;
      default:
    }
  };

  const handleLogout = () => {
    navigate("/");
    localStorage?.clear();
  };

  const handleSetSortValue = () => {
    localStorage.setItem("sortValue", "");
  };

  const handleViewNotification = () => {
    setOpenNoti((prev) => !prev);
  };

  const items = [
    {
      key: "2",
      icon: <UserOutlined style={{ fontSize: "1.2rem" }} />,
      label: <NavLink to="/profile-setting">Profile </NavLink>,
    },
    {
      key: "11",
      icon: <LogoutOutlined style={{ fontSize: "1.2rem" }} />,
      label: <span onClick={handleLogout}>Logout</span>,
    },
  ];

  const navItems = [
    {
      className: activeNavbar === 1 ? "active--navbar" : "",
      key: "1",
      icon: <AreaChartOutlined />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(1)}
          to="/dashboard"
        >
          Insights
        </NavLink>
      ),
    },
    {
      className: activeNavbar === 2 ? "active--navbar" : "",
      key: "2",
      icon: <UserOutlined />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(2)}
          to="/profile-setting"
        >
          Profile
        </NavLink>
      ),
    },

    {
      key: "1004",
      icon: <BiMessageAlt />,
      className: activeNavbar === 44 ? "active--navbar" : "",
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(44)}
          to={"/find-agent-inquiry/inquiry-list"}
        >
          Inquiry List
        </NavLink>
      ),
    },

    {
      key: "1004",
      icon: <MdPayments />,
      className: activeNavbar === 445544 ? "active--navbar" : "",
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(445544)}
          to={"/packages"}
        >
          Packages
        </NavLink>
      ),
    },

    {
      key: "7",
      className: activeNavbar === 7 ? "active--navbar" : "",
      icon: <CreditCardOutlined />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(7)}
          to="/payment-history"
        >
          Payment History
        </NavLink>
      ),
    },
    {
      key: "712",
      className: activeNavbar === 712 ? "active--navbar" : "",
      icon: <FcSupport />,
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(712)}
          to="/support"
        >
          Support{" "}
        </NavLink>
      ),
    },

    {
      key: "10",
      icon: <UsergroupAddOutlined />,
      label: "Setting",
      children: [
        !is_agent && {
          key: "1001",
          className: activeNavbar === 1001 ? "active--navbar" : "",
          label: (
            <NavLink
              className={({ isActive }) => isActive && setActiveNavbar(1001)}
              to={"/find-agent-inquiry/setting"}
            >
              Inquiry Setting
            </NavLink>
          ),
        },

        {
          key: "8",
          className: activeNavbar === 8 ? "active--navbar" : "",
          label: (
            <NavLink
              className={({ isActive }) => isActive && setActiveNavbar(8)}
              to="/privacy-setting"
            >
              Privacy Settings
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "9",
      icon: <PlusSquareOutlined />,
      label: "Referral Management",
      children: [
        {
          key: "901",
          className: activeNavbar === 901 ? "active--navbar" : "",
          label: (
            <NavLink
              className={({ isActive }) => isActive && setActiveNavbar(901)}
              to={"/referral/invite-friend"}
            >
              Invite Friend
            </NavLink>
          ),
        },
        {
          key: "902",
          className: activeNavbar === 902 ? "active--navbar" : "",
          label: (
            <NavLink
              className={({ isActive }) => isActive && setActiveNavbar(902)}
              to={"/referral/ref-dashboard"}
            >
              Referral Dashboard
            </NavLink>
          ),
        },
      ],
    },
    {
      key: "4",
      className: activeNavbar === 4 ? "active--navbar" : "",
      icon: <StarOutlined />,
      style: {
        color: "black",
      },
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(4)}
          to="/coupon"
        >
          Coupon
        </NavLink>
      ),
    },
    {
      key: "10011",
      icon: <SiCodereview />,
      className: activeNavbar === 10011 ? "active--navbar" : "",
      label: (
        <NavLink
          className={({ isActive }) => isActive && setActiveNavbar(10011)}
          to={"/my-reviews"}
        >
          My Reviews
        </NavLink>
      ),
    },
  ];

  return (
    <Layout>
      <Sider
        className="dashboard--slider sider"
        style={{
          backgroundColor: "white",
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "sticky",
          top: 0,
          left: 0,
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="dashboard--logo">
          {collapsed ? (
            <img
              onClick={() => navigate("/dashboard")}
              src={logo2}
              style={{
                padding: "0.3rem",
                width: "80px",
                height: "auto",
                cursor: "pointer",
              }}
              alt="classiEstate"
            />
          ) : (
            <img
              onClick={() => navigate("/dashboard")}
              style={{
                padding: "0.3rem",
                width: "240px",
                height: "auto",
                cursor: "pointer",
              }}
              src={logo3}
              alt="classiEstateAdmin"
            />
          )}
        </div>
        <div className="demo-logo-vertical" />
        <Menu
          theme="light"
          mode="inline"
          style={{
            backgroundColor: "white",
            color: "black",
          }}
          items={navItems}
        />
      </Sider>
      <Layout>
        <Header className="layout--header">
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "1.5rem",
              width: 64,
              height: 64,
            }}
            className="show--hide--component"
          />
          <MenuOutlined
            onClick={() => setOpenDrawer(true)}
            className="show--hide--menu"
          />
          {/* tab--mobile--drawer */}
          <TabMobileDrawer
            navItems={navItems}
            activeNavbar={activeNavbar}
            setActiveNavbar={setActiveNavbar}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleLogout={handleLogout}
            handleViewNotification={handleViewNotification}
            handleSetSortValue={handleSetSortValue}
          />
          <img
            onClick={() => navigate("/dashboard")}
            style={{
              width: "10rem",
              height: "4rem",
              objectFit: "contain",
              cursor: "pointer",
            }}
            className="show--hide--image"
            src={logo2}
            alt="classiEstateAdmin"
          />

          <div className="show--hide--menu">
            {/* <NavLink to="/ad-property">
              <HomeOutlined
                style={{
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  color: "#0b3e75ce",
                }}
              />
            </NavLink> */}
            <Badge
              size="default"
              count={
                notificationList?.no_of_unseen
                  ? notificationList?.no_of_unseen
                  : 0
              }
            >
              <BellOutlined
                onClick={handleViewNotification}
                style={{
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  color: "#0b3e75ce",
                }}
              />
            </Badge>

            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow
            >
              {profileImage ? (
                <img
                  style={{
                    width: "2rem",
                    height: "2rem",
                    borderRadius: "100%",
                    objectFit: "contain",
                    cursor: "pointer",
                    background: "#D8D9DA",
                  }}
                  src={profileImage}
                  alt=""
                />
              ) : (
                <Avatar style={{ cursor: "pointer", fontSize: "1rem" }}>
                  {profileData?.first_name?.length > 0
                    ? profileData?.first_name[0]
                    : ""}
                </Avatar>
              )}
            </Dropdown>
          </div>
          <div className="post--notification show--hide--component">
            {/* <NavLink className="show--hide--component" to="/ad-property">
              <span
                className="button--modal--home"
                style={{
                  height: "2.2rem",
                  cursor: "pointer",
                  borderRadius: "2rem",
                  fontWeight: "600",
                }}
              >
                <HomeOutlined style={{ fontSize: "1rem" }} /> Post An Ad
              </span>
            </NavLink> */}
            <Badge
              size="default"
              count={
                notificationList?.no_of_unseen
                  ? notificationList?.no_of_unseen
                  : 0
              }
            >
              <span
                onClick={handleViewNotification}
                className="notification--icon"
                style={{ cursor: "pointer" }}
              >
                <BellOutlined
                  style={{
                    fontSize: "1.1rem",
                    color: "#0b3e75ce",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                />
              </span>
            </Badge>

            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow
            >
              {profileImage ? (
                <img
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "100%",
                    objectFit: "contain",
                    background: "#D8D9DA",
                    cursor: "pointer",
                  }}
                  src={profileImage}
                  alt=""
                />
              ) : (
                <Avatar
                  style={{
                    cursor: "pointer",
                    fontSize: "1.5rem",
                    width: "2.5rem",
                    textAlign: "center",
                    height: "2.5rem",
                  }}
                >
                  {profileData?.first_name?.length > 0
                    ? profileData?.first_name[0]
                    : ""}
                </Avatar>
              )}
            </Dropdown>
          </div>
        </Header>
        <div
          style={{
            width: "100%",
            minHeight: "92vh",
          }}
        >
          <div
            className={
              openNoti
                ? "notification--container--active overflow--scroll"
                : "notification--container--icon"
            }
          >
            <div className="small--device">
              <CloseCircleOutlined
                onClick={() => setOpenNoti(false)}
                className="notifi--close--button"
              />
            </div>
            {notification.length > 0
              ? notification.map((item, index) => {
                  if (notification?.length === index + 1) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                          padding: "0.5rem",
                          borderRadius: "2px",
                          background: "#F5F5F5",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        ref={lastNotificationRef}
                        onClick={() => handleNotificationClick(item)}
                      >
                        <img
                          src={logo}
                          alt=""
                          style={{
                            height: "3rem",
                            width: "3rem",
                            objectFit: "contain",
                          }}
                        />
                        <span>{item?.body}</span>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                          padding: "0.5rem",
                          borderRadius: "2px",
                          background: "#F5F5F5",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={() => handleNotificationClick(item)}
                      >
                        <img
                          src={logo}
                          alt=""
                          style={{
                            height: "3rem",
                            width: "3rem",
                            objectFit: "contain",
                          }}
                        />
                        <span>{item?.body}</span>
                      </div>
                    );
                  }
                })
              : !notificationloading && (
                  <span style={{ color: "black" }}> No Notification</span>
                )}
            {
              notificationloading && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spin indicator={antIcon} />;
                </div>
              )
              // <div>loading...</div>
            }
          </div>
          <div
            onClick={() => setOpenNoti(false)}
            className={openNoti ? "hidden--conatiner" : ""}
          ></div>
          <Content className="style--content--container">{children}</Content>
        </div>
      </Layout>
    </Layout>
  );
};
export default connect(mapStateToProps)(DashboardLayout);

export const TabMobileDrawer = ({
  openDrawer,
  setOpenDrawer,
  handleSetSortValue,
  activeNavbar,
  setActiveNavbar,
  navItems,
}) => {
  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Drawer title={null} placement="left" onClose={onClose} open={openDrawer}>
      <Menu
        theme="light"
        mode="inline"
        style={{
          backgroundColor: "white",
          color: "black",
        }}
        items={navItems}
      />
    </Drawer>
  );
};
