import { Avatar, Divider, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { ImAttachment } from "react-icons/im";
import {
  CloseOutlined,
  SendOutlined,
  LoadingOutlined,
  UploadOutlined,
  AlertOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { GrAttachment } from "react-icons/gr";

import { allActions } from "../../Redux/myActions";
import { useDispatch, connect } from "react-redux";

import EstateAgent from "../../assets/estateAgent.jpg";
import ChatGroup from "../../assets/chat.jpg";
import { useRef } from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import {
  containsImageExtension,
  downloadImage,
} from "../../Helpers/FrontendHelper";

const mapStateToProps = (store) => {
  const { ProfileReducer } = store;

  return { ProfileReducer };
};

const OffersMessage = ({ ProfileReducer }) => {
  const dispatch = useDispatch();
  const {
    offerMessageData,
    offerUserList,
    activeUserChatList,
    activeuserloading,
    activeuserchatloading,
    sendmessageloading,
  } = ProfileReducer;

  const [offerProperty, setOfferProperty] = useState({});
  const [isActiveContainer, setIsActiveContainer] = useState(false);
  const [offerMessageText, setOfferMessageText] = useState();
  const [activeUser, setActiveUser] = useState();
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [file, setFile] = useState("");
  const fileRef = useRef();

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "offermessages_new",
          attempt: "FETCH_OFFER_MESSAGE_REQUEST_ATTEMPT",
          success: "FETCH_OFFER_MESSAGE_REQUEST_SUCCESS",
          failure: "FETCH_OFFER_MESSAGE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            res?.offers?.length > 0 &&
              fetctOfferUserList(res?.offers[0]?.post_id);
            res?.offers?.length > 0 && setOfferProperty(res?.offers[0]);
          },
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const fetctOfferUserList = (post_id) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `offerlists?post_id=${post_id}`,
          attempt: "FETCH_OFFERMESSAGE_USER_REQUEST_ATTEMPT",
          success: "FETCH_OFFERMESSAGE_USER_REQUEST_SUCCESS",
          failure: "FETCH_OFFERMESSAGE_USER_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  const handleOpenOfferMessageChat = (activeUser) => {
    setActiveUser((prev) => activeUser);

    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `offers_detail?post_id=${activeUser?.post_id}&offer_id=${activeUser?.id}`,
          attempt: "FETCH_ACTIVE_USER_CHAT_REQUEST_ATTEMPT",
          success: "FETCH_ACTIVE_USER_CHAT_REQUEST_SUCCESS",
          failure: "FETCH_ACTIVE_USER_CHAT_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
    setIsActiveContainer((prev) => true);
  };

  const handleOfferMessageInputChange = (e) => {
    setOfferMessageText(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSendOfferMessage();
    }
  };

  const handleSendOfferMessage = () => {
    if (isFileUploaded) {
      const form = new FormData();
      form.append("files", file);

      const onlyWhitespace = /^\s*$/.test(file);
      onlyWhitespace
        ? message.warning("Please Upload the file")
        : dispatch(
            allActions(form, {
              method: "post",
              endPoint: "upload_comment_file",
              attempt: "POST_OFFER_FILE_REQUEST_ATTEMPT",
              success: "POST_OFFER_FILE_REQUEST_SUCCESS",
              failure: "POST_OFFER_FILE_REQUEST_FAILURE",
              multipartFormData: true,
              saveBearerToken: false,
              successInternalState: (res) => {
                handleSendMessage({ fileName: res?.source?.name });
              },
              failureInternalState: () => {
                setFile("");
                setIsFileUploaded(false);
              },
            })
          );
    } else {
      const onlyWhitespace = /^\s*$/.test(offerMessageText);

      onlyWhitespace
        ? message?.warning("Please type the message")
        : handleSendMessage({ fileName: "" });
    }
  };

  const handleSendMessage = ({ fileName }) => {
    dispatch(
      allActions(
        {
          comment: isFileUploaded ? file?.name : offerMessageText,
          file_name: fileName,
          post_id: activeUser?.post_id,
          offer_id: activeUser?.id,
          app_name: "CLASSIESTATE",
        },
        {
          method: "post",
          endPoint: "add_comment",
          attempt: "POST_OFFER_MESSAGE_REQUEST_ATTEMPT",
          success: "POST_OFFER_MESSAGE_REQUEST_SUCCESS",
          failure: "POST_OFFER_MESSAGE_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {
            setIsFileUploaded(false);
            setFile("");
            setOfferMessageText("");
            handleOpenOfferMessageChat(activeUser);
          },
          failureInternalState: () => {
            setIsFileUploaded(false);
            setFile("");
            setOfferMessageText("");
          },
        }
      )
    );
  };

  const handleChangeFileUpload = (e) => {
    setFile(e.target.files[0]);
    setIsFileUploaded((prev) => true);
  };

  return (
    <div className="offersmessage--container--component">
      <h3 style={{ padding: "1rem" }}>Inbox</h3>
      <Divider style={{ margin: "0" }} />
      {offerMessageData?.offers?.length > 0 ? (
        <div className="offermessage--inner--conatainer">
          <div
            className={`offermessage--inner--container-left overflow--scroll ${
              isActiveContainer ? "drawer-small" : "drawer-equal"
            } `}
          >
            {offerMessageData?.offers?.map((item) => {
              return (
                <div
                  key={item}
                  className={`
                    ${
                      item?.post_id === offerProperty?.post_id
                        ? "active--property company--message--container"
                        : "company--message--container "
                    }  `}
                  onClick={() => {
                    setOfferProperty((prev) => item);
                    fetctOfferUserList(item?.post_id);
                    setIsActiveContainer((prev) => false);
                  }}
                >
                  <img
                    style={{
                      height: "3rem",
                      width: "3rem",
                      objectFit: "cover",
                      border: "1px solid #80808080",
                      background: "white",
                      borderRadius: "3px",
                    }}
                    src={item?.image}
                    alt={""}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <span>{item.title}</span>
                    <span
                      className={
                        item?.unseen_msg
                          ? "active--user--component"
                          : "inactive--user--component"
                      }
                    ></span>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {!isActiveContainer ? (
              <div className="offermessage--inner--container-right overflow--scroll">
                {activeuserloading ? (
                  <span> Loading...</span>
                ) : (
                  offerUserList?.replys?.map((item) => (
                    <div
                      onClick={() => handleOpenOfferMessageChat(item)}
                      className={
                        isActiveContainer
                          ? "inactive--user--container"
                          : "company--message--container"
                      }
                    >
                      <img
                        style={{
                          height: "3rem",
                          width: "3rem",
                          objectFix: "contain",
                          borderRadius: "100%",
                          border: "1px solid gray",
                        }}
                        src={item?.image}
                        alt=""
                      />
                      <span style={{ flex: 1 }}>{item?.offer_name}</span>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                          alignItems: "center",
                        }}
                      >
                        <span
                          className={
                            item?.unseen_msg
                              ? "active--user--component"
                              : "inactive--user--component"
                          }
                        ></span>
                      </div>
                    </div>
                  ))
                )}
              </div>
            ) : (
              <div
                className={
                  isActiveContainer
                    ? "offer--message--chat--container active--chat--container"
                    : "offer--message--chat--container "
                }
              >
                <div className="offer--message--chat--header">
                  {activeUserChatList?.length > 0 && (
                    <>
                      <img
                        src={activeUser?.image}
                        alt=""
                        style={{
                          width: "2.5rem",
                          height: "2.5rem",
                          objectFit: "contain",
                          border: "1px solid gray",
                          borderRadius: "100%",
                        }}
                      />

                      <span style={{ flex: 1 }}>{activeUser?.offer_name}</span>
                    </>
                  )}
                  <CloseOutlined
                    style={{
                      fontSize: "1.2rem",
                      color: "#0b3e75ce",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsActiveContainer((prev) => false)}
                  />
                </div>
                <ScrollToBottom
                  style={{
                    overflowY: "scroll",
                  }}
                >
                  <div style={{ height: "19rem" }}>
                    <div className="offer--message--chat--body overflow--scroll">
                      {activeUserChatList?.length > 0 &&
                        activeUserChatList?.map((item, index) => (
                          <div
                            className={
                              activeUser?.offer_name?.toLowerCase() ===
                                `${item?.first_name?.toLowerCase()} ${item?.last_name?.toLowerCase()}` ||
                              index === 0
                                ? "sender"
                                : "receiver"
                            }
                          >
                            <div
                              className={
                                activeUser?.offer_name?.toLowerCase() ===
                                  `${item?.first_name?.toLowerCase()} ${item?.last_name?.toLowerCase()}` ||
                                index === 0
                                  ? "sender--inner--div"
                                  : "receiver--inner--div"
                              }
                            >
                              {item?.file_name ? (
                                containsImageExtension(item?.file_name) ? (
                                  <div>
                                    <img
                                      src={item?.file_name}
                                      style={{
                                        width: "12rem",
                                        height: "auto",
                                        maxHeight: "9rem",
                                        objectFit: "cover",
                                      }}
                                      alt=""
                                    />

                                    <span
                                      onClick={() =>
                                        downloadImage(
                                          item?.file_name,
                                          item?.comment
                                        )
                                      }
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        gap: "0.3rem",
                                        padding: "0.3rem 0.4rem",
                                        fontSize: "12px",
                                        borderRadius: "0.5rem",
                                        cursor: "pointer",
                                        alignItems: "center",
                                      }}
                                    >
                                      {" "}
                                      <GrAttachment /> download
                                    </span>
                                  </div>
                                ) : (
                                  <a
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      gap: "0.3rem",
                                      border: "1px solid #78a2ff",
                                      padding: "0.3rem 0.4rem",
                                      fontSize: "12px",
                                      borderRadius: "0.5rem",
                                      cursor: "pointer",
                                      alignItems: "center",
                                    }}
                                    href={item?.file_name}
                                  >
                                    <GrAttachment />{" "}
                                    {item?.comment
                                      ? item?.comment
                                      : "Attachment..."}
                                  </a>
                                )
                              ) : (
                                <p
                                  className={
                                    activeUser?.offer_name?.toLowerCase() ===
                                      `${item?.first_name?.toLowerCase()} ${item?.last_name?.toLowerCase()}` ||
                                    index === 0
                                      ? "sender--message"
                                      : "receiver--message"
                                  }
                                >
                                  {item?.comment}
                                </p>
                              )}
                              <p>
                                {" "}
                                <span style={{ fontSize: "0.7rem" }}>
                                  {item?.first_name}
                                </span>
                                ,
                                <span style={{ fontSize: "0.7rem" }}>
                                  {" "}
                                  {item?.added_date}
                                </span>
                              </p>
                            </div>

                            <img
                              src={
                                activeUser?.offer_name?.toLowerCase() ===
                                  `${item?.first_name?.toLowerCase()} ${item?.last_name?.toLowerCase()}` ||
                                index === 0
                                  ? activeUser?.image
                                  : localStorage?.getItem("profileImage")
                              }
                              alt=""
                              style={{
                                width: "2rem",
                                height: "2rem",
                                objectFit: "contain",
                                border: "1px solid gray",
                                borderRadius: "100%",
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </ScrollToBottom>
                <div className="offer--message--chat--footer">
                  <input
                    ref={fileRef}
                    onChange={handleChangeFileUpload}
                    // accept=".pdf,.doc,.docx"
                    type="file"
                    style={{ display: "none" }}
                  />
                  {isFileUploaded ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        alignItems: "center",
                        textAlign: "center",
                        background: "white",
                        padding: "0.5rem ",
                      }}
                    >
                      {file?.name}{" "}
                      <CloseCircleOutlined
                        onClick={() => {
                          setIsFileUploaded((prev) => false);
                          setFile("");
                        }}
                        style={{
                          fontSize: "1rem",
                          color: "red",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  ) : (
                    <ImAttachment
                      className="inquiry--file--upload"
                      onClick={() => fileRef.current.click()}
                    />
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                      alignItems: "center",
                      border: isFileUploaded ? "none" : "1px solid #DFD7BF",
                      width: isFileUploaded ? "fit-content" : "100%",
                      paddingRight: "0.5rem",
                      borderRadius: "0.5rem",
                    }}
                  >
                    {!isFileUploaded && (
                      <Input
                        style={{
                          padding: "0.5rem",
                          outline: "none",
                          border: "none",
                        }}
                        value={offerMessageText}
                        onKeyDown={handleKeyPress}
                        onChange={handleOfferMessageInputChange}
                        placeholder="Type here..."
                      />
                    )}

                    {sendmessageloading ? (
                      <LoadingOutlined className="inquiry--send--button" />
                    ) : (
                      <SendOutlined
                        onClick={handleSendOfferMessage}
                        className="inquiry--send--button"
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={ChatGroup}
            style={{
              position: "absolute",
              top: "3rem",
              margin: "0 auto",
              height: "3rem",
              width: "3rem",
            }}
            alt=""
          />
          <img
            src={EstateAgent}
            style={{ width: "12rem", height: "12rem", objectFit: "contain" }}
            alt=""
          />
          <span style={{ color: "gray", fontSize: "1rem" }}>
            No Chat Available
          </span>
        </div>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(OffersMessage);
