import { UserOutlined } from "@ant-design/icons";
import { Avatar, Rate } from "antd";
import moment from "moment";
import React from "react";

export default function ReviewElement({ review }) {
  console.log("review", review);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        border: "1px solid #cfcfcf90",
        borderRadius: "1rem",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "space-between",
          gap: "0.5rem",
        }}
      >
        {review?.thumbnail ? (
          <img
            src={review?.thumbnail}
            style={{
              display: "flex",
              flex: "row",
              alignItems: "center",
              width: "3rem",
              height: "3rem",
              objectFit: "cover",
              borderRadius: "100%",
            }}
          />
        ) : (
          <Avatar
            size={48}
            style={{
              display: "flex",
              flex: "row",
              alignItems: "center",
            }}
            icon={<UserOutlined style={{ margin: "auto" }} />}
          />
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Rate
            disabled
            defaultValue={review?.rate ? parseFloat(review?.rate) : 0}
          />
          <span>
            {review?.gmt
              ? moment(review?.gmt).local().format("DD MMMM YYYY")
              : null}
          </span>
        </div>
      </div>
      <div style={{ display: "flex", flexDirection: "column", flex: "1" }}>
        <h3>
          {review?.first_name} {review?.last_name}
        </h3>
      </div>
      <p style={{ color: "gray", margin: "0.5rem 0" }}>{review?.message}</p>
    </div>
  );
}
