import React, { useEffect, useState } from "react";
import ReviewElement from "../../component/reviewElement/ReviewElement";
import { allActions } from "../../Redux/myActions";
import { useDispatch } from "react-redux";
import { Empty, Pagination } from "antd";

export default function ReviewComponent() {
  const dispatch = useDispatch();
  const [reviewList, setReviewList] = useState();
  const [current, setCurrent] = useState(
    reviewList?.current_page ? reviewList?.current_page : 1
  );

  useEffect(() => {
    fetchReviewList();
  }, []);

  const fetchReviewList = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `lead/reviews/${localStorage.getItem("userId")}`,
          attempt: "FETCH_REVIEW_LIST_REQUEST_ATTEMPT",
          success: "FETCH_REVIEW_LIST_REQUEST_SUCCESS",
          failure: "FETCH_REVIEW_LIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            setReviewList(res);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <div
      style={{
        background: "white",
        display: "grid",
        // flexDirection: "column",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gap: "0.5rem",
        padding: "1rem",
      }}
    >
      {reviewList?.reviews?.length > 0 ? (
        reviewList?.reviews?.map((review) => <ReviewElement review={review} />)
      ) : (
        <Empty style={{ gridColumn: "span 4" }} />
      )}
      {/* <div style={{ gridColumn: "span 4" }}>
        <Pagination
          onChange={(value) => {
            setCurrent(value);
          }}
          current={current}
          total={
            reviewList?.total_number
              ? parseInt(reviewList?.total_number / 10)
              : 1
          }
        />
      </div> */}
    </div>
  );
}
