import { CloseOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, InputNumber, Select } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { allActions } from "../../../Redux/myActions";

export default function CompanyDocument({ formData, setFormData, setCurrent }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(null);
  const { docTypeList } = useSelector((store) => store?.ProfileReducer);

  const handleUpdateDocument = (values) => {
    setFormData((prev) => ({ ...prev, ...values }));
    isNext ? setCurrent((prev) => prev + 1) : setCurrent((prev) => prev - 1);
  };

  const handleUploadDocument = (e, index) => {
    const tempValue = form.getFieldValue("documents");
    // form.setFieldValue("sn", index + 1);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append(`files[]`, file);

    dispatch(
      allActions(formData, {
        method: "post",
        endPoint: "upload_lead_image",
        attempt: "IMAGE_UPLOAD_REQUEST_ATTEMPT",
        success: "IMAGE_UPLOAD_REQUEST_SUCCESS",
        failure: "IMAGE_UPLOAD_REQUEST_FAILURE",

        saveBearerToken: false,
        multipartFormData: true,
        successInternalState: (res) => {
          tempValue[index].url = res?.source[0]?.image_name;
          form.setFieldValue("documents", tempValue);
        },
        failureInternalState: () => {},
      })
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={{ documents: formData?.documents }}
      onFinish={handleUpdateDocument}
    >
      <Form.List name="documents">
        {(fields, { add, remove }) => (
          <div
            style={{
              display: "flex",
              rowGap: 16,
              flexDirection: "column",
            }}
          >
            {fields.map((field, index) => (
              <Card
                size="small"
                title={`Document ${field.name + 1}`}
                key={field.key}
                extra={
                  <CloseOutlined
                    style={{ color: "red" }}
                    onClick={() => {
                      remove(field.name);
                    }}
                  />
                }
              >
                <div
                  style={{
                    padding: "0 1rem",
                    display: "grid",
                    gridTemplateColumns: "2fr 2fr",
                    gap: "1rem",
                  }}
                >
                  {/* <Form.Item label="S.N" name={[field.name, "sn"]}>
                    <InputNumber
                      style={{ width: "100%" }}
                      placeholder="S.N"
                      readOnly
                    />
                  </Form.Item> */}
                  <Form.Item label="Document Type" name={[field.name, "type"]}>
                    <Select placeholder="Document Type">
                      {docTypeList?.types?.map((doc) => (
                        <Select.Option value={doc?.id}>
                          {doc?.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Document" name={[field.name, "document"]}>
                    <Input
                      style={{ width: "100%" }}
                      type="file"
                      placeholder="Document"
                      onChange={(e) => handleUploadDocument(e, index)}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{ display: "none" }}
                    label="Document"
                    name={[field.name, "url"]}
                  >
                    <Input
                      style={{ width: "100%" }}
                      placeholder="Document"
                      // onChange={(e) => handleUploadDocument(e, index)}
                    />
                  </Form.Item>
                  {/* <Form.Item label="Status" name={[field.name, "status"]}>
                    <Input placeholder="Status" readOnly />
                  </Form.Item> */}
                </div>
              </Card>
            ))}

            <Button
              style={{ marginBottom: "1rem" }}
              type="dashed"
              onClick={() => add()}
              block
            >
              + Add Document
            </Button>
          </div>
        )}
      </Form.List>

      <Button.Group
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={() => setIsNext(false)}
          type="primary"
          htmlType="submit"
        >
          Back
        </Button>
        <Button
          onClick={() => setIsNext(true)}
          type="primary"
          htmlType="submit"
        >
          Next
        </Button>
      </Button.Group>
    </Form>
  );
}
