import React, { useState } from "react";
import { Input, Form } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useDispatch, connect } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { MdAlternateEmail } from "react-icons/md";

import { allActions } from "../../Redux/myActions";
import LoaderPrimary from "../../component/loader/LoaderPrimary";

import logo3 from "../../assets/classileads.png";
import { IoMdCodeWorking } from "react-icons/io";

const mapStateToProps = (store) => {
  const AuthReducer = store.AuthReducer;
  return AuthReducer;
};

const Signup = (AuthReducer) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cpasswordVisible, setcPasswordVisible] = useState(false);

  const { loading } = AuthReducer;

  const handleSubmitSignup = (values) => {
    dispatch(
      allActions(
        {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
          refferal_code: values?.refferal_code,
        },
        {
          method: "post",
          endPoint: "signup",
          attempt: "SIGNUP_REQUEST_ATTEMPT",
          success: "SIGNUP_REQUEST_SUCCESS",
          failure: "SIGNUP_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: (res) => {
            localStorage.setItem("userEmail", values?.email);
            localStorage.setItem("userCode", values?.password);

            localStorage.setItem("code", res?.code);
            localStorage.setItem("userId", res?.userid);
            localStorage.setItem("isGmail", false);
            navigate("/auth/verify-otp", { state: { isSuccessOtp: true } });
          },
          failureInternalState: (err) => {
            localStorage.setItem("userEmail", values?.email);
            localStorage.setItem("userCode", values?.password);

            // localStorage.setItem("code", res?.code);
            // localStorage.setItem("userId", res?.userid);
            err?.message === "Email already exists." &&
              dispatch(
                allActions(
                  {
                    email: values.email,
                  },
                  {
                    method: "post",
                    endPoint: "resend_activation_code",
                    attempt: "RESENTOTP_REQUEST_ATTEMPT",
                    success: "RESENTOTP_REQUEST_SUCCESS",
                    failure: "RESENDOTP_REQUEST_FAILURE",

                    saveBearerToken: false,
                    successInternalState: (res) => {
                      localStorage.setItem("code", res?.code);
                      navigate("/auth/agent-request");
                    },
                    failureInternalState: () => {},
                  }
                )
              );
          },
        }
      )
    );
  };

  return (
    <div className="signup--container">
      <div className="signup--container--body">
        {loading && <LoaderPrimary />}

        <Form
          layout="vertical"
          className="form--container"
          onFinish={handleSubmitSignup}
        >
          <img
            src={logo3}
            style={{
              padding: "0.3rem",
              width: "300px",
              height: "auto",
            }}
            alt="classiEstate Logo"
          />
          <p
            className="login--header"
            style={{ fontWeight: "semibold", marginBottom: "1rem" }}
          >
            Signup new account!{" "}
          </p>
          <div className="form--inner--container">
            <Form.Item
              className="signup--input"
              name="firstName"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ padding: "0.5rem" }}>
                      FirstName is required
                    </span>
                  ),
                },
              ]}
            >
              <Input
                className="signup--input"
                size="large"
                type="text"
                placeholder="First Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
            <Form.Item
              className="signup--input"
              name="lastName"
              rules={[
                {
                  required: true,
                  message: (
                    <span style={{ padding: "0.5rem" }}>
                      LastName is required
                    </span>
                  ),
                },
              ]}
            >
              <Input
                className="signup--input"
                size="large"
                type="text"
                placeholder="Last Name"
                prefix={<UserOutlined className="site-form-item-icon" />}
              />
            </Form.Item>
          </div>

          <Form.Item
            className="signup--input"
            name="email"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>Email is required</span>
                ),
              },
            ]}
          >
            <Input
              className="signup--input"
              size="large"
              type="email"
              placeholder="Email"
              prefix={<MdAlternateEmail className="site-form-item-icon" />}
            />
          </Form.Item>
          <Form.Item
            className="signup--input"
            name="password"
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    Password is required
                  </span>
                ),
              },
            ]}
          >
            <Input.Password
              className="signup--input"
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Password"
              visibilityToggle={{
                visible: passwordVisible,
                onVisibleChange: setPasswordVisible,
              }}
            />
          </Form.Item>
          <Form.Item
            className="signup--input"
            name="cPassword"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: (
                  <span style={{ padding: "0.5rem" }}>
                    Confirm password is required
                  </span>
                ),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Confirm password do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password
              className="signup--input"
              size="large"
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder="Confirm Password"
              visibilityToggle={{
                visible: cpasswordVisible,
                onVisibleChange: setcPasswordVisible,
              }}
            />
          </Form.Item>
          <Form.Item className="signup--input" name="refferal_code">
            <Input
              className="signup--input"
              size="large"
              placeholder="Refferal Code"
              prefix={<IoMdCodeWorking className="site-form-item-icon" />}
            />
          </Form.Item>
          <button
            disabled={loading}
            type="submit"
            className="login--button"
            style={{
              border: "none",
              width: "100%",
              borderRadius: "1rem !important",
              fontSize: "1rem",
            }}
          >
            SIGN UP
          </button>
        </Form>
        <p style={{ fontSize: "14px", textAlign: "center", marginTop: "1rem" }}>
          Do you have an account with Classileads?
          <Link to={"/"}> Sign In</Link>
        </p>
      </div>
    </div>
  );
};

export default connect(mapStateToProps)(Signup);
